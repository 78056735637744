<template>
  <b-modal id="show-img" hide-footer title="">
    <vueper-slides fade :touchable="false">
      <vueper-slide
        v-for="(item, i) in dataImage.file_image"
        :key="i"
        :image="item"
      />
    </vueper-slides>
  </b-modal>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
export default {
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: {
    dataImage: {
      type: Object,
    },
  },
};
</script>
<style scoped src="@/assets/css/TP_AD1/TPAD1_ShowImage_Modal.css"></style>
<style scoped>
::v-deep .modal-content {
  background-color: #f5f5f5 !important;
  width: 230%;
  bottom: 150px;
  right: 60%;
}

::v-deep .vueperslides__inner {
  height: 500px !important;
}
/* ::v-deep .vueperslide__content-wrapper {
height: 500px !important;
} */
::v-deep .vueperslides__parallax-wrapper {
  height: 500px !important;
}

::v-deep .vueperslide {
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
