<template>
  <div class="div-all-list">
    <Menu :isFirst_4="true"></Menu>
    <div class="content">
      <b-card no-body>
        <b-tabs card>
          <!-- Earthquake -->
          <b-tab title="地震発生時の設定" active>
            <div class="row-line">
              <div class="w-100px">条件 <span style="color: red">*</span></div>
              <div style="width: 25%">
                <multiselect
                  :multiple="false"
                  :options="listEarthquake"
                  :show-labels="false"
                  v-model="selectedEarthquake"
                  placeholder=""
                >
                </multiselect>
              </div>
            </div>
            <div class="row-line time-setting" style="margin-top: 20px">
              <div class="w-100px time-title">時間設定</div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="earthquakeTimespend" @blur="resetData('earthquakeTimespend')" />
              </div>
              <div class="text-unit">時間</div>
              <div class="w-100px time-title" style="padding-left: 3%">
                最大回数
              </div>
              <div style="width: 8.5%" class="time-input">
                <b-input
                  type="number"
                  min="0"
                  v-model="earthquakeNumberSpend"
                  @blur="resetData('earthquakeNumberSpend')"
                />
              </div>
              <div class="text-unit">回</div>

              <div class="w-100px time-title" style="padding-left: 3%">
                回答期限
              </div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="earthquakeExpire" @blur="resetData('earthquakeExpire')"
                />
              </div>
              <div class="text-unit">日</div>

              <!-- <div class="w-100px time-title"  name="number-resend">Time_input</div>
              <div style="width: 25%" class="time-input">
                <input type="number" placeholder="2" v-model="test"/>
                </div>
              <div class="w-100px time-title" name="number-expire">自動再送日数</div>
              <div style="width: 25%" class="time-input">
                <input type="number" placeholder="2" v-model="test1" />
              </div> -->
            </div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px">
                <span style="margin-left: 22px"> 表題</span
                ><span style="color: red">*</span><br />
                <span>（メール)</span>
              </div>
              <div style="width: 30%">
                <b-input v-model="earthquakeMailSubject" />
              </div>
            </div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px">
                <span style="margin-left: 22px"> 本文</span
                ><span style="color: red">*</span><br />
                (メール·LINE) <span></span>
              </div>
              <div style="width: 30%">
                <b-textarea rows="6" v-model="earthquakeMailBody"></b-textarea>
              </div>
            </div>
            <div class="text-line">
              ※本文についてはメール·LINEに同じ内容が送信されます。
            </div>
            <div class="push">Push通知内容</div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px"></div>
              <div style="width: 30%">
                <b-textarea
                  rows="6"
                  maxlength="30"
                  v-model="earthquakeNotifyBody"
                ></b-textarea>
              </div>
            </div>
            <div class="text-30">※30 文字までで入力してください。</div>
            <div class="div-btn">
              <button
                type="button"
                class="btn button-cancel"
                @click="onClickCancelEarthquake()"
              >
                クリアー
              </button>
              <button
                v-b-modal.modal-setting
                type="button"
                class="btn button-register"
                @click="addOrUpdateEarthquake()"
              >
                <img
                  v-if="waiting"
                  class="icon-load"
                  src="@/assets/img/common/loadingdot.gif"
                  alt="loading"
                />
                設定
              </button>
            </div>
          </b-tab>

          <!-- tsunami -->
          <b-tab title="津波発生時の設定">
            <div class="row-line">
              <div class="w-100px">条件 <span style="color: red">*</span></div>
              <div style="width: 25%">
                <multiselect
                  :multiple="false"
                  :options="listTsunami"
                  :show-labels="false"
                  v-model="selectedTsunami"
                  placeholder=""
                >
                </multiselect>
              </div>
            </div>
            <div class="row-line time-setting" style="margin-top: 20px">
              <div class="w-100px time-title">時間設定</div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="tsunamiTimespend"  @blur="resetData('tsunamiTimespend')"/>
              </div>

              <div class="text-unit">時間</div>
              <div class="w-100px time-title" style="padding-left: 3%">
                最大回数
              </div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="tsunamiNumberSpend"  @blur="resetData('tsunamiNumberSpend')"/>
              </div>
              <div class="text-unit">回</div>

              <div class="w-100px time-title" style="padding-left: 3%">
                回答期限
              </div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="tsunamiExpire" @blur="resetData('tsunamiExpire')"/>
              </div>
              <div class="text-unit">日</div>
            </div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px">
                <span style="margin-left: 22px"> 表題</span
                ><span style="color: red">*</span><br />
                <span>（メール)</span>
              </div>
              <div style="width: 30%">
                <b-input v-model="tsunamiMailSubject" />
              </div>
            </div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px">
                <span style="margin-left: 22px"> 本文</span
                ><span style="color: red">*</span><br />
                (メール·LINE) <span></span>
              </div>
              <div style="width: 30%">
                <b-textarea rows="6" v-model="tsunamiMailBody"></b-textarea>
              </div>
            </div>
            <div class="text-line">
              ※本文についてはメール·LINEに同じ内容が送信されます。
            </div>
            <div class="push">Push通知内容</div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px"></div>
              <div style="width: 30%">
                <b-textarea
                  rows="6"
                  maxlength="30"
                  v-model="tsunamiNotifyBody"
                ></b-textarea>
              </div>
            </div>
            <div class="text-30">※30 文字までで入力してください。</div>
            <div class="div-btn">
              <button
                type="button"
                class="btn button-cancel"
                @click="onClickCancelTsunami()"
              >
                クリアー
              </button>
              <button
                v-b-modal.modal-setting
                type="button"
                class="btn button-register"
                @click="addOrUpdateTsunami()"
              >
                <img
                  v-if="waiting"
                  class="icon-load"
                  src="@/assets/img/common/loadingdot.gif"
                  alt="loading"
                />
                設定
              </button>
            </div>
          </b-tab>

          <!-- jmaweather -->
          <b-tab title="警報発生時の設定">
            <div class="row-line">
              <div class="w-100px">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  @change="checkAllSpecial($event)"
                  :checked="isCheckAllSpecail"
                />&nbsp;特別警報
              </div>
            </div>
            <div class="row-line">
              <div class="condition-200">
                <div class="checkbox-special">
                  <div
                    v-for="(item, index) in list_special_weather"
                    :key="index"
                  >
                    <input
                      style="cursor: pointer"
                      type="checkbox"
                      @change="changeCheckbox($event, item)"
                      :checked="
                        listJimaweatherIdSpecail.some(
                          (checked) => checked === item.report_code
                        )
                      "
                    />
                    <span class="title-warning">{{ item.category_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-line">
              <div class="w-100px">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  @change="checkAllWarning($event)"
                  :checked="isCheckAllWarning"
                />&nbsp;警報
              </div>
            </div>
            <div class="row-line">
              <div class="condition-200">
                <div class="checkbox-special">
                  <div
                    v-for="(item, index) in list_warning_weather"
                    :key="index"
                  >
                    <input
                      style="cursor: pointer"
                      type="checkbox"
                      @change="changeCheckboxWarning($event, item)"
                      :checked="
                        listJimaweatherIdWarning.some(
                          (checked) => checked === item.report_code
                        )
                      "
                    />
                    <span class="title-warning">{{ item.category_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-line time-setting" style="margin-top: 20px">
              <div class="w-100px time-title">時間設定</div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="jmaTimespend" @blur="resetData('jmaTimespend')"/>
              </div>
              <div class="text-unit">時間</div>

              <div class="w-100px time-title" style="padding-left: 3%">
                最大回数
              </div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="jmaNumberSpend" @blur="resetData('jmaNumberSpend')"/>
              </div>
              <div class="text-unit">回</div>

              <div class="w-100px time-title" style="padding-left: 3%">
                回答期限
              </div>
              <div style="width: 8.5%" class="time-input">
                <b-input type="number" min="0" v-model="jmaExpire" @blur="resetData('jmaExpire')"/>
              </div>
              <div class="text-unit">日</div>
            </div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px">
                <span style="margin-left: 22px"> 表題</span
                ><span style="color: red">*</span><br />
                <span>（メール)</span>
              </div>
              <div style="width: 30%">
                <b-input v-model="jimaweatherMailSubject" />
              </div>
            </div>
            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px">
                <span style="margin-left: 22px"> 本文</span
                ><span style="color: red">*</span><br />
                (メール·LINE) <span></span>
              </div>
              <div style="width: 30%">
                <b-textarea rows="6" v-model="jimaweatherMailBody"></b-textarea>
              </div>
            </div>
            <div class="text-line">
              ※本文についてはメール·LINEに同じ内容が送信されます。
            </div>
            <div class="push">Push通知内容</div>

            <div class="row-line" style="margin-top: 20px">
              <div class="w-100px"></div>
              <div style="width: 30%">
                <b-textarea
                  rows="6"
                  maxlength="30"
                  v-model="jimaweatherNotifyBody"
                ></b-textarea>
              </div>
            </div>
            <div class="text-30">※30 文字までで入力してください。</div>
            <div class="div-btn">
              <button
                type="button"
                class="btn button-cancel"
                @click="onClickCancelJmaweather()"
              >
                クリアー
              </button>
              <button
                v-b-modal.modal-setting
                type="button"
                class="btn button-register"
                @click="addOrUpdateJmaweather()"
              >
                <img
                  v-if="waiting"
                  class="icon-load"
                  src="@/assets/img/common/loadingdot.gif"
                  alt="loading"
                />
                設定
              </button>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <ModalSuccess :notificationContent="notificationSuccess"></ModalSuccess>
    <ModalFail :notificationContent="notificationFail"></ModalFail>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Menu from "../../../src/components/common/AdminMenu_Comp.vue";
import axios from "axios";
import Const from "../../constant/constant.js";
import ModalSuccess from "../../components/common/Modal_Success.vue";
import ModalFail from "../../components/common/Modal_Fail.vue";

export default {
  components: {
    Multiselect,
    Menu,
    ModalSuccess,
    ModalFail,
  },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      listEarthquake: [
        "震度4",
        "震度5弱",
        "震度5強",
        "震度6弱",
        "震度6強",
        "震度7",
      ],
      selectedEarthquake: "",
      selectedTsunami: "",
      listDayResend: [
        "再送信設定なし",
        "1日",
        "2日",
        "3日",
        "4日",
        "5日",
        "6日",
        "7日",
      ],
      earthquakeTimespend: "",
      earthquakeNumberSpend: "",
      earthquakeExpire: "",
      tsunamiTimespend: "",
      tsunamiNumberSpend: "",
      tsunamiExpire: "",
      jmaTimespend: "",
      jmaNumberSpend: "",
      jmaExpire: "",
      selectedDayResendTab1: "",
      selectedDayResendTab2: "",
      selectedDayResendTab3: "",
      listTsunami: ["0.3m", "0.5m", "1.0m", "3.0m", "5.0m"],
      listJimaweather: [],
      listJimaweatherId: [],
      earthquakeMailSubject: "",
      earthquakeMailBody: "",
      tsunamiMailSubject: "",
      tsunamiMailBody: "",
      jimaweatherMailSubject: "",
      jimaweatherMailBody: "",
      earthquakeLevel: 0,
      tsunamiLevel: 0,
      dayResendEarthquake: 0,
      dayResendTsunami: 0,
      dayResendJimaweather: 0,
      waiting: false,
      settingInfo: null,
      notificationSuccess: "",
      notificationFail: "",
      earthquakeNotifySubject: "",
      earthquakeNotifyBody: "",
      tsunamiNotifySubject: "",
      tsunamiNotifyBody: "",
      jimaweatherNotifySubject: "",
      jimaweatherNotifyBody: "",
      list_special_weather: [],
      list_warning_weather: [],
      isCheckAllSpecail: false,
      isCheckAllWarning: false,
      listJimaweatherIdSpecail: [],
      listJimaweatherIdWarning: [],
      special_length: 0,
      warning_length: 0,
      data_special: [],
      data_warning: [],
    };
  },

  created() {
    Promise.all([this.getDataSetting(), this.getDataJimaweather()])
      .then(() => {
        const isCheckAllSpecialList = this.list_special_weather.filter(
          (element) =>
            this.listJimaweatherIdSpecail.includes(element.report_code)
        );
        this.isCheckAllSpecail =
          isCheckAllSpecialList.length === this.list_special_weather.length;
        this.data_special = isCheckAllSpecialList;

        const isCheckAllWarningList = this.list_warning_weather.filter(
          (element) =>
            this.listJimaweatherIdSpecail.includes(element.report_code)
        );
        this.isCheckAllWarning =
          isCheckAllWarningList.length === this.list_warning_weather.length;
        this.data_warning = isCheckAllWarningList;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    resetData(variable){
      if(this[variable]<0){
        this[variable] = 0
      }
    },
    onClickCancelEarthquake() {
      this.selectedEarthquake = "震度5弱";
      this.selectedDayResendTab1 = "1日";
      this.earthquakeMailSubject = "";
      this.earthquakeMailBody = "";
      this.earthquakeNotifySubject = "";
      this.earthquakeNotifyBody = "";
    },

    onClickCancelTsunami() {
      this.selectedTsunami = "5.0m";
      this.selectedDayResendTab2 = "1日";
      this.tsunamiMailSubject = "";
      this.tsunamiMailBody = "";
      this.tsunamiNotifySubject = "";
      this.tsunamiNotifyBody = "";
    },

    onClickCancelJmaweather() {
      this.isCheckAllSpecail = true;
      let data = [];
      this.list_special_weather.forEach((element) => {
        data.push(element.report_code);
      });
      this.listJimaweatherIdSpecail = data;
      this.data_special = this.list_special_weather.filter((element) =>
        this.listJimaweatherIdSpecail.includes(element.report_code)
      );
      this.isCheckAllWarning = false;
      let dataWarning = [];
      this.listJimaweatherIdWarning = dataWarning;
      this.data_warning = this.list_warning_weather.filter((element) =>
        this.listJimaweatherIdWarning.includes(element.report_code)
      );
      this.selectedDayResendTab3 = "1日";
      this.jimaweatherMailSubject = "";
      this.jimaweatherMailBody = "";
      this.jimaweatherNotifySubject = "";
      this.jimaweatherNotifyBody = "";
      this.waiting = false;
    },

    checkInputRequiredEarthquake() {
      let isRequired = true;
      if (
        !this.selectedEarthquake ||
        (this.earthquakeMailSubject.trim() === "" &&
          this.earthquakeMailBody.trim() === "" &&
          this.earthquakeNotifyBody.trim() === "") ||
        (this.earthquakeMailSubject.trim() === "" &&
          this.earthquakeMailBody.trim() !== "") ||
        (this.earthquakeMailSubject.trim() !== "" &&
          this.earthquakeMailBody.trim() == "")
      ) {
        isRequired = false;
        this.notificationFail = "は入力必須項目です";
      }
      return isRequired;
    },

    checkInputRequiredTsunami() {
      let isRequired = true;
      if (
        !this.selectedTsunami ||
        (this.tsunamiMailSubject.trim() === "" &&
          this.tsunamiMailBody.trim() === "" &&
          this.tsunamiNotifyBody.trim() === "") ||
        (this.tsunamiMailSubject.trim() === "" &&
          this.tsunamiMailBody.trim() !== "") ||
        (this.tsunamiMailSubject.trim() !== "" &&
          this.tsunamiMailBody.trim() == "")
      ) {
        isRequired = false;
        this.notificationFail = "は入力必須項目です";
      }

      return isRequired;
    },

    checkInputRequiredJimaweather() {
      let isRequired = true;
      if (
        this.listJimaweatherId.length === 0 ||
        (this.jimaweatherMailSubject.trim() === "" &&
          this.jimaweatherNotifyBody.trim() === "" &&
          this.jimaweatherMailBody.trim() === "") ||
        (this.jimaweatherMailSubject.trim() === "" &&
          this.jimaweatherMailBody.trim() !== "") ||
        (this.jimaweatherMailSubject.trim() !== "" &&
          this.jimaweatherMailBody.trim() == "")
      ) {
        isRequired = false;
        this.notificationFail = "は入力必須項目です";
      }
      return isRequired;
    },
    convertDataInit() {
      // conver value earthquake level
      switch (this.earthquakeLevel) {
        case 3.5:
          this.selectedEarthquake = "震度4";
          break;
        case 4.5:
          this.selectedEarthquake = "震度5弱";
          break;
        case 5.0:
          this.selectedEarthquake = "震度5強";
          break;
        case 5.5:
          this.selectedEarthquake = "震度6弱";
          break;
        case 6.0:
          this.selectedEarthquake = "震度6強";
          break;
        case 6.5:
          this.selectedEarthquake = "震度7";
      }

      // conver value tsunami level
      switch (this.tsunamiLevel) {
        case 0.3:
          this.selectedTsunami = "0.3m";
          break;
        case 0.5:
          this.selectedTsunami = "0.5m";
          break;
        case 1.0:
          this.selectedTsunami = "1.0m";
          break;
        case 3.0:
          this.selectedTsunami = "3.0m";
          break;
        case 5.0:
          this.selectedTsunami = "5.0m";
      }

      // conver value date earthquake
      switch (this.dayResendEarthquake) {
        case 0:
          this.selectedDayResendTab1 = "再送信設定なし";
          break;
        case 1:
          this.selectedDayResendTab1 = "1日";
          break;
        case 2:
          this.selectedDayResendTab1 = "2日";
          break;
        case 3:
          this.selectedDayResendTab1 = "3日";
          break;
        case 4:
          this.selectedDayResendTab1 = "4日";
          break;
        case 5:
          this.selectedDayResendTab1 = "5日";
          break;
        case 6:
          this.selectedDayResendTab1 = "6日";
          break;
        case 7:
          this.selectedDayResendTab1 = "7日";
      }

      // conver value date tsunami
      switch (this.dayResendTsunami) {
        case 0:
          this.selectedDayResendTab2 = "再送信設定なし";
          break;
        case 1:
          this.selectedDayResendTab2 = "1日";
          break;
        case 2:
          this.selectedDayResendTab2 = "2日";
          break;
        case 3:
          this.selectedDayResendTab2 = "3日";
          break;
        case 4:
          this.selectedDayResendTab2 = "4日";
          break;
        case 5:
          this.selectedDayResendTab2 = "5日";
          break;
        case 6:
          this.selectedDayResendTab2 = "6日";
          break;
        case 7:
          this.selectedDayResendTab2 = "7日";
      }

      // conver value date jiweather
      switch (this.dayResendJimaweather) {
        case 0:
          this.selectedDayResendTab3 = "再送信設定なし";
          break;
        case 1:
          this.selectedDayResendTab3 = "1日";
          break;
        case 2:
          this.selectedDayResendTab3 = "2日";
          break;
        case 3:
          this.selectedDayResendTab3 = "3日";
          break;
        case 4:
          this.selectedDayResendTab3 = "4日";
          break;
        case 5:
          this.selectedDayResendTab3 = "5日";
          break;
        case 6:
          this.selectedDayResendTab3 = "6日";
          break;
        case 7:
          this.selectedDayResendTab3 = "7日";
      }
    },
    convertDataParam() {
      // conver value earthquake level
      switch (this.selectedEarthquake) {
        case "震度4":
          this.earthquakeLevel = 3.5;
          break;
        case "震度5弱":
          this.earthquakeLevel = 4.5;
          break;
        case "震度5強":
          this.earthquakeLevel = 5.0;
          break;
        case "震度6弱":
          this.earthquakeLevel = 5.5;
          break;
        case "震度6強":
          this.earthquakeLevel = 6.0;
          break;
        case "震度7":
          this.earthquakeLevel = 6.5;
      }

      // conver value tsunami level
      switch (this.selectedTsunami) {
        case "0.3m":
          this.tsunamiLevel = 0.3;
          break;
        case "0.5m":
          this.tsunamiLevel = 0.5;
          break;
        case "1.0m":
          this.tsunamiLevel = 1.0;
          break;
        case "3.0m":
          this.tsunamiLevel = 3.0;
          break;
        case "5.0m":
          this.tsunamiLevel = 5.0;
      }

      // conver value date earthquake
      switch (this.selectedDayResendTab1) {
        case !this.selectedDayResendTab1:
          this.dayResendEarthquake = 0;
          break;
        case "再送信設定なし":
          this.dayResendEarthquake = 0;
          break;
        case "1日":
          this.dayResendEarthquake = 1;
          break;
        case "2日":
          this.dayResendEarthquake = 2;
          break;
        case "3日":
          this.dayResendEarthquake = 3;
          break;
        case "4日":
          this.dayResendEarthquake = 4;
          break;
        case "5日":
          this.dayResendEarthquake = 5;
          break;
        case "6日":
          this.dayResendEarthquake = 6;
          break;
        case "7日":
          this.dayResendEarthquake = 7;
      }

      // conver value date tsunami
      switch (this.selectedDayResendTab2) {
        case !this.selectedDayResendTab2:
          this.dayResendTsunami = 0;
          break;
        case "再送信設定なし":
          this.dayResendTsunami = 0;
          break;
        case "1日":
          this.dayResendTsunami = 1;
          break;
        case "2日":
          this.dayResendTsunami = 2;
          break;
        case "3日":
          this.dayResendTsunami = 3;
          break;
        case "4日":
          this.dayResendTsunami = 4;
          break;
        case "5日":
          this.dayResendTsunami = 5;
          break;
        case "6日":
          this.dayResendTsunami = 6;
          break;
        case "7日":
          this.dayResendTsunami = 7;
      }

      // conver value date jiweather
      switch (this.selectedDayResendTab3) {
        case !this.selectedDayResendTab3:
          this.dayResendJimaweather = 0;
          break;
        case "再送信設定なし":
          this.dayResendJimaweather = 0;
          break;
        case "1日":
          this.dayResendJimaweather = 1;
          break;
        case "2日":
          this.dayResendJimaweather = 2;
          break;
        case "3日":
          this.dayResendJimaweather = 3;
          break;
        case "4日":
          this.dayResendJimaweather = 4;
          break;
        case "5日":
          this.dayResendJimaweather = 5;
          break;
        case "6日":
          this.dayResendJimaweather = 6;
          break;
        case "7日":
          this.dayResendJimaweather = 7;
      }
    },

    // Function add/update setting for Earthquake
    addOrUpdateEarthquake() {
      this.convertDataParam();
      let param = {
        earthquake_obj: {
          event_type: "earthquake",
          level_value: this.earthquakeLevel,
          report_code: "",
          after_hour_resend: this.earthquakeTimespend,
          resend_times: this.earthquakeNumberSpend,
          day_expire: this.earthquakeExpire,
          mail_subject: this.earthquakeMailSubject,
          mail_body: this.earthquakeMailBody,
          push_title: this.earthquakeNotifySubject,
          push_content: this.earthquakeNotifyBody,
        },
      };
      let url =
        Const.ROOT_API + Const.SETTING_PATH + Const.ADD_OR_UPDATE_SETTING;

      if (this.checkInputRequiredEarthquake()) {
        this.waiting = true;
        axios
          .post(url, param)
          .then((res) => {
            this.waiting = false;
            if (res.data.message === Const.MSG_SUCCESS) {
              this.notificationSuccess = "設定を保存しました。";
              this.$bvModal.show("modal-noti-success");
            } else {
              this.notificationFail = "Update fail!";
              this.$bvModal.show("modal-noti-fail");
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$bvModal.show("modal-noti-fail");
      }
    },

    // Function add/update setting for Earthquake
    addOrUpdateTsunami() {
      this.convertDataParam();
      let param = {
        tsunami_obj: {
          event_type: "tsunami",
          level_value: this.tsunamiLevel,
          report_code: "",
          after_hour_resend: this.tsunamiTimespend,
          resend_times: this.tsunamiNumberSpend,
          day_expire: this.tsunamiExpire,
          mail_subject: this.tsunamiMailSubject,
          mail_body: this.tsunamiMailBody,
          push_title: this.tsunamiNotifySubject,
          push_content: this.tsunamiNotifyBody,
        },
      };
      let url =
        Const.ROOT_API + Const.SETTING_PATH + Const.ADD_OR_UPDATE_SETTING;

      if (this.checkInputRequiredTsunami()) {
        this.waiting = true;
        axios
          .post(url, param)
          .then((res) => {
            this.waiting = false;
            if (res.data.message === Const.MSG_SUCCESS) {
              this.notificationSuccess = "設定を保存しました。";
              this.$bvModal.show("modal-noti-success");
            } else {
              this.notificationFail = "Update fail!";
              this.$bvModal.show("modal-noti-fail");
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$bvModal.show("modal-noti-fail");
      }
    },

    addOrUpdateJmaweather() {
      let data = [];
      this.data_special.forEach((x) => {
        data.push(x.report_code);
      });
      this.data_warning.forEach((x) => {
        data.push(x.report_code);
      });
      this.listJimaweatherId = data;
      this.convertDataParam();

      let param = {
        jimaweather_obj: {
          event_type: "jmaweather",
          report_code: this.listJimaweatherId,
          level_value: 0,
          after_hour_resend: this.jmaTimespend,
          resend_times: this.jmaNumberSpend,
          day_expire: this.jmaExpire,
          mail_subject: this.jimaweatherMailSubject,
          mail_body: this.jimaweatherMailBody,
          push_title: this.jimaweatherNotifySubject,
          push_content: this.jimaweatherNotifyBody,
        },
      };
      let url =
        Const.ROOT_API + Const.SETTING_PATH + Const.ADD_OR_UPDATE_SETTING;

      if (this.checkInputRequiredJimaweather()) {
        this.waiting = true;
        axios
          .post(url, param)
          .then((res) => {
            this.waiting = false;
            if (res.data.message === Const.MSG_SUCCESS) {
              this.notificationSuccess = "設定を保存しました。";
              this.$bvModal.show("modal-noti-success");
            } else {
              this.notificationFail = "Update fail!";
              this.$bvModal.show("modal-noti-fail");
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$bvModal.show("modal-noti-fail");
      }
    },
    getDataJimaweather() {
      let url =
        Const.ROOT_API + Const.SETTING_PATH + Const.GET_DATA_JIMAWEATHER;
      return axios
        .get(url)
        .then((res) => {
          if (res.data.message === Const.MSG_SUCCESS) {
            this.list_special_weather = res.data.special_weather;
            this.list_warning_weather = res.data.warning_weather;
            this.special_length = res.data.special_weather.length;
            this.warning_length = res.data.warning_weather.length;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    getDataSetting() {
      let url = Const.ROOT_API + Const.SETTING_PATH + Const.GET_DATA_SETTING;
      return axios
        .get(url)
        .then((res) => {
          if (res.data.message === Const.MSG_SUCCESS) {
            this.settingInfo = res.data.data;
            this.earthquakeLevel = this.settingInfo["0"].level_value;
            this.earthquakeTimespend = this.settingInfo["0"].after_hour_resend;
            this.earthquakeNumberSpend = this.settingInfo["0"].resend_times;
            this.earthquakeExpire = this.settingInfo["0"].day_expire;
            this.earthquakeMailSubject = this.settingInfo["0"].mail_title;
            this.earthquakeMailBody = this.settingInfo["0"].mail_body;
            this.earthquakeNotifySubject = this.settingInfo["0"].push_title;
            this.earthquakeNotifyBody = this.settingInfo["0"].push_content;

            this.tsunamiLevel = this.settingInfo["1"].level_value;
            this.tsunamiMailSubject = this.settingInfo["1"].mail_title;
            this.tsunamiMailBody = this.settingInfo["1"].mail_body;
            this.tsunamiNotifySubject = this.settingInfo["1"].push_title;
            this.tsunamiNotifyBody = this.settingInfo["1"].push_content;
            this.tsunamiTimespend = this.settingInfo["1"].after_hour_resend;
            this.tsunamiNumberSpend = this.settingInfo["1"].resend_times;
            this.tsunamiExpire = this.settingInfo["1"].day_expire;

            this.listJimaweatherIdSpecail =
              this.settingInfo["2"].special_weather;
            this.listJimaweatherIdWarning =
              this.settingInfo["2"].special_weather;
            this.dayResendJimaweather = this.settingInfo["2"].after_day_resend;
            this.jimaweatherMailSubject = this.settingInfo["2"].mail_title;
            this.jimaweatherMailBody = this.settingInfo["2"].mail_body;
            this.jimaweatherNotifySubject = this.settingInfo["2"].push_title;
            this.jimaweatherNotifyBody = this.settingInfo["2"].push_content;
            this.jmaTimespend = this.settingInfo["2"].after_hour_resend;
            this.jmaNumberSpend = this.settingInfo["2"].resend_times;
            this.jmaExpire = this.settingInfo["2"].day_expire;

            this.convertDataInit();
          }
        })
        .catch((err) => {
          return err;
        });
    },
    changeCheckbox(event, item) {
      if (event.target.checked) {
        this.listJimaweatherIdSpecail.push(item.report_code);
        this.data_special.push(item);

        this.isCheckAllSpecail =
          this.data_special.length == this.special_length;
      } else {
        this.isCheckAllSpecail = false;
        this.data_special = this.data_special.filter(
          (x) => x.report_code !== item.report_code
        );
        let index = this.listJimaweatherIdSpecail.indexOf(item.report_code);
        this.listJimaweatherIdSpecail.splice(index, 1);
      }
    },
    changeCheckboxWarning(event, item) {
      if (event.target.checked) {
        this.listJimaweatherIdWarning.push(item.report_code);
        this.data_warning.push(item);
        this.isCheckAllWarning =
          this.data_warning.length == this.warning_length;
      } else {
        this.isCheckAllWarning = false;
        this.data_warning = this.data_warning.filter(
          (x) => x.report_code !== item.report_code
        );
        let index = this.listJimaweatherIdWarning.indexOf(item.report_code);
        this.listJimaweatherIdWarning.splice(index, 1);
      }
    },
    checkAllSpecial(event) {
      if (event.target.checked) {
        this.isCheckAllSpecail = true;
        this.listJimaweatherIdSpecail = [];
        this.data_special = [...this.list_special_weather];
        this.list_special_weather.forEach((item) => {
          this.listJimaweatherIdSpecail.push(item.report_code);
        });
      } else {
        this.isCheckAllSpecail = false;
        this.listJimaweatherIdSpecail = [];
        this.data_special = [];
      }
    },
    checkAllWarning(event) {
      if (event.target.checked) {
        this.isCheckAllWarning = true;
        this.listJimaweatherIdWarning = [];
        this.data_warning = [...this.list_warning_weather];
        this.list_warning_weather.forEach((item) => {
          this.listJimaweatherIdWarning.push(item.report_code);
        });
      } else {
        this.isCheckAllWarning = false;
        this.listJimaweatherIdWarning = [];
        this.data_warning = [];
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/TP_AD3/TPAD3_List_Setting.css"></style>
<style scoped>
::v-deep a {
  color: black !important;
}
::v-deep .text-unit {
  margin-top: 7px;
  margin-left: 9px;
}
::v-deep .modal-content {
  background-color: #f5f5f5 !important;
  width: 170%;
  bottom: 150px;
  right: 100px;
}

::v-deep .mx-input {
  margin-left: 20px;
  padding-left: 30px;
  text-align: center !important;
}
::v-deep .div-pick-to-month .mx-input-wrapper .mx-input {
  width: 179px;
}
#editor {
  height: 220px;
}
textarea {
  resize: none;
}
::v-deep .ql-editor {
  min-height: 170px !important;
  font-size: 14px;
  background-color: white;
}
</style>
