import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import Vue2Editor from "vue2-editor";
import BootstrapVue from 'bootstrap-vue'
import Quill from "vue2-editor";
import jQuery from "jquery";

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

Vue.config.productionTip = false
window.$ = window.jQuery = jQuery;
Vue.use(BootstrapVue, Vue2Editor, Quill, VueperSlides, VueperSlide)
new Vue({
  router,
  Vue2Editor,
  Quill,
  VueperSlides,
  VueperSlide,
  render: h => h(App),
}).$mount('#app')
