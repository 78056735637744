<template>
  <b-modal id="modal-noti-fail" hide-header hide-footer>
    <div class="body-modal-error">
      <img class="icon-cancel" src="@/assets/img/common/icon_Cancel.svg" />
      <span class="title-error">{{ notificationContent }}</span>
      <span class="error-btn-close" @click="hideModal()">&#x2715;</span>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    notificationContent: {
      type: String,
      default: "",
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-noti-fail");
    },
  },
};
</script>
<style scoped src="@/assets/css/common/Modal_Fail.css"></style>
<style scoped>
::v-deep .modal-body {
  padding: 0;
  background: rgba(255, 93, 23, 0.11);
}
::v-deep .modal-content {
  border-radius: 7px;
  border: solid 1px #ff5d17;
  width: 80%;
  height: 78px;
}
</style>
