<template>
  <div class="div-all-list">
    <Menu :isFirst_1="true"></Menu>
    <div class="content">
      <LoadingData v-if="isLoading"></LoadingData>
      <div>
        <div
          v-bind:class="['div-find', this.isLoading === true ? 'disable' : '']"
        >
          <DivFilter
            @dataFilter="handleEventFilter"
            :openDivFilter="showFillter"
            @openDivFillter="openDivFillter"
            :dataFilterInput="dataFilterInput"
          ></DivFilter>
        </div>
        <div class="div-body-content" v-if="!isLoading">
          <div class="top-body-content">
            <div class="div-action-page">
              <div class="action-left">
                <div class="total-list" v-bind:title="'閲覧確認一覧'">
                  <span>&ensp;安否確認一覧</span>
                </div>
                <div class="total-rate">
                  <span class="text-number">{{
                    formatThreeDigitAfterComma(dataNum)
                  }}</span>
                  <span>&ensp;件</span>
                </div>
              </div>
              <div class="action-right">
                <div
                  class="download-selected poitier-mouse"
                  v-bind:title="'画像ダウンロード'"
                >
                  <img
                    v-if="!waitingDownloadIMG"
                    class="cloud-download"
                    src="@/assets/img/common/cloud_download-24px.svg"
                    alt="画像ダウンロード"
                  />
                  <img
                    v-if="waitingDownloadIMG"
                    class="icon-load"
                    src="@/assets/img/common/loading_download.gif"
                    alt="loading"
                  />
                  <span class="text-update-status" @click="downloadImageFilter"
                    >画像ダウンロード</span
                  >
                </div>
                <div
                  class="download-selected poitier-mouse"
                  v-bind:title="'一覧CSV'"
                >
                  <img
                    v-if="!waitingDownloadCSVFilter"
                    class="cloud-download"
                    src="@/assets/img/common/cloud_download-24px.svg"
                    alt="一覧CSV"
                  />
                  <img
                    v-if="waitingDownloadCSVFilter"
                    class="icon-load"
                    src="@/assets/img/common/loading_download.gif"
                    alt="loading"
                  />
                  <span
                    class="text-update-status"
                    @click="downloadAllDataDetailFilter"
                    >一覧CSV</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="div-data-table">
            <div>
              <div class="header-table">
                <div class="text-date" v-bind:title="'日付'">No.</div>
                <div
                  class="therapist-name w-therapist-name"
                  v-bind:title="'セラピストNo'"
                >
                  セラピストNo
                </div>
                <div class="number-day" v-bind:title="'セラピスト名'">
                  <div>セラピスト名</div>
                </div>
                <div
                  class="poor-physical-day w-poor-physical-day"
                  v-bind:title="'現住所都道府県'"
                >
                  現住所都道府県
                </div>
                <div class="store-name" v-bind:title="'最終業務店舗'">
                  最終業務店舗
                </div>
                <div
                  class="poor-physical-day w-poor-physical-day"
                  v-bind:title="'最終業務店舗都道府県'"
                >
                  最終業務店舗都道府県
                </div>
                <div
                  class="poor-physical-day w-poor-physical-day"
                  v-bind:title="'最終業務店舗エリア'"
                >
                  最終業務店舗エリア
                </div>
                <div class="last-day" v-bind:title="'最終業務日'">
                  最終業務日
                </div>
                <div class="area" v-bind:title="'当日業務有無'">
                  当日業務有無
                </div>
                <div class="prefectures" v-bind:title="'発報情報'">
                  発報情報
                </div>
                <div class="start-time" v-bind:title="'発報区分'">発報区分</div>
                <div class="ending-time" v-bind:title="'回答有無'">
                  回答有無
                </div>
                <div
                  class="temperature-measurement-time"
                  v-bind:title="'回答日時'"
                >
                  回答日時
                </div>
                <div class="body-temperature" v-bind:title="'けがの状況'">
                  けがの状況
                </div>
                <div class="physical-condition" v-bind:title="'業務可否'">
                  業務可否
                </div>

                <div class="state-of-shop" v-bind:title="'店舗状況'">
                  店舗状況
                </div>
                <div
                  class="file-image w-file-image"
                  v-bind:title="'添付ファイル'"
                >
                  添付ファイル
                </div>
                <div class="comment" v-bind:title="'特記事項'">特記事項</div>
              </div>
            </div>

            <!-- Search data does not exist -->
            <FilterNotData
              v-if="this.data == 0"
              class="reward-list-nothing"
              @showDivFilter="openDivFillter"
            ></FilterNotData>

            <!-- Search data exists -->
            <div
              id="content-table"
              class="row-content-table"
              v-if="this.data != 0"
            >
              <div
                class="poitier-mouse"
                v-for="(item, index) in data"
                :key="index"
              >
                <Collapse
                  :index="index"
                  :item="item"
                  :currentPage="currentPage"
                  :limitItem="limitItem"
                  :showItem="showItem"
                  :openEvent="openEvent"
                  :dateFormat="dateFormat"
                  :dateTimeFormat="dateTimeFormat"
                  :onShowModalImage="onShowModalImage"
                >
                </Collapse>
              </div>
            </div>

            <div class="sellect-number-paginate" v-if="this.data != 0">
              <PaginateComponent
                :currentPage="this.currentPage"
                :limit="this.limitItem"
                :totalPages="this.totalPages"
                @pagechanged="reloadPage"
              ></PaginateComponent>
            </div>
          </div>
        </div>
        <DetailSafety
          v-if="isShowDetailSafety"
          :dataModal="dataModal"
          @onClose="() => (isShowDetailSafety = false)"
        ></DetailSafety>
        <DetailEventInformation :dataModal="dataModal"></DetailEventInformation>
        <ModalImageFail :notificationContent="this.messageError"></ModalImageFail>
        <ShowImageModal :dataImage="dataImage"></ShowImageModal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Const from "../../constant/constant.js";
import Menu from "../../../src/components/common/AdminMenu_Comp.vue";
import { FormatThreeDigitAfterComma } from "../../assets/js/common/FormatThreeDigitAfterComma";
import DivFilter from "@/components/TP_AD1/TPAD1_Filter_Comp.vue";
import FilterNotData from "@/components/TP_AD1/TPAD1_FilterNotData.vue";
import DetailSafety from "@/components/TP_AD1/TPAD1_DetailSafety.vue";
import PaginateComponent from "../../../src/components/common/PaginateComponent.vue";
import LoadingData from "../../components/common/LoadingData_Comp";
import DetailEventInformation from "@/components/TP_AD1/TPAD1_DetailEvent_Information.vue";
import { DateFormat } from "../../assets/js/common/DateFormat";
import ShowImageModal from "@/components/TP_AD1/TPAD1_ShowImage_Modal.vue";
import moment from "moment";
import Collapse from "../../components/common/Collapse_Comp/index.vue";
import ModalImageFail from "@/components/TP_AD1/TPAD1_ImageWarning_Error.vue";


export default {
  components: {
    Menu,
    DivFilter,
    DetailSafety,
    PaginateComponent,
    LoadingData,
    ShowImageModal,
    FilterNotData,
    Collapse,
    DetailEventInformation,
    ModalImageFail,
  },
  name: "ListSafetyConfirmation",
  data: function () {
    return {
      urlGetListConfirm:
        Const.ROOT_API + Const.SUBMIT_PATH + Const.GET_LIST_CONFIRM,
      urlDownloadCSV:
        Const.ROOT_API + Const.SUBMIT_PATH + Const.DOWLOAD_ALL_CSV,
      urlDownloadDetailCSV:
        Const.ROOT_API + Const.SUBMIT_PATH + Const.DOWNLOAD_DETAIL_CSV,
      urlDownloadDetailIMG:
        Const.ROOT_API + Const.SUBMIT_PATH + Const.DOWNLOAD_IMG,
      urlAPIFilterData:
        Const.ROOT_API + Const.SUBMIT_PATH + Const.GET_DATA_FILTER,
      dataModal: {},
      arrayList: 0,
      arrayListSelect: 0,
      waitingDownloadDetail: false,
      waitingDownloadCSVFilter: false,
      waitingDownloadIMG: false,
      openDivFilter: false,
      showFillter: false,
      listVideo: [],
      isLoading: true,
      data: [],
      totalPages: 0,
      currentPage: 1,
      limitItem: 20,
      dataNum: 0,
      filtering: false,
      messageError: "",
      isShowDetailEvent: false,
      dataFilterInput: {
        therapist_no: "",
        shop_name: "",
        shop_area: "",
        is_working: 0,
        last_work_date: "",
        event_id: 0,
        event_type: "",
        is_auto_send: 0,
        is_answer: 0,
        state_of_health: 0,
        is_can_work: 0,
        state_of_shop: 0,
        file_image: 0,
        comment: 0,
        limit: 20,
        page: 1,
        start_date: "",
        end_date: "",
      },
      dataImage: {},
      startDate: "",
      endDate: "",
      //
      isShowDetailSafety: false,
    };
  },
  mixins: [FormatThreeDigitAfterComma, DateFormat],
  created() {},
  mounted() {
    localStorage.type_login = "tp_weather";
    this.urlGetListConfirm +=
      "?limit=" + this.limitItem + "&page=" + this.currentPage;
    this.getData(this.urlGetListConfirm);
  },
  methods: {
    /*
     * Call API
     *
     * @param url (Url API)
     */
    getData: function (url) {
      axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data.data;
            if (res.data.numberPage != 0) {
              this.totalPages = res.data.numberPage;
              this.dataNum = res.data.length;
            } else {
              this.dataNum = 0;
            }
          } else {
            this.data = null;
            this.dataNum = 0;
            this.totalPages = 0;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          return err;
        });
    },
    // show div filter
    openDivFillter() {
      this.showFillter = !this.showFillter;
    },
    handleEventFilter: function (data) {
      this.isLoading = true;

      this.currentPage = 1;
      this.dataFilterInput = data;
      this.dataFilterInput.page = 1;
      this.limitItem = this.dataFilterInput.limit;

      this.$forceUpdate();
      this.filtering = true;
      this.postDataFilter(
        this.urlAPIFilterData,
        this.dataFilterInput,
        false,
        false
      );
    },
    reloadPage: function (data) {
      this.isLoading = true;
      this.currentPage = data.page;
      // check has fillter
      if (this.filtering) {
        this.dataFilterInput.page = data.page;

        if (this.dataFilterInput.limit != data.limit) {
          this.dataFilterInput.page = 1;
          this.currentPage = 1;
          var changeLimit = true;
        }
        this.dataFilterInput.limit = data.limit;
        this.limitItem = data.limit;
        this.postDataFilter(
          this.urlAPIFilterData,
          this.dataFilterInput,
          true,
          changeLimit
        );
      } else {
        if (this.limitItem != data.limit) {
          this.currentPage = 1;
        }
        this.limitItem = data.limit;
        let api =
          Const.ROOT_API +
          Const.SUBMIT_PATH +
          Const.GET_LIST_CONFIRM +
          "?page=" +
          this.currentPage +
          "&limit=" +
          this.limitItem;
        this.getData(api);
      }
    },
    postDataFilter: function (url, body, isNextPage, changeLimit) {
      axios.post(url, body).then((res) => {
        if (res.status == 200) {
          this.data = res.data.data;
          if (!isNextPage || changeLimit) {
            this.dataNum = res.data.length;
            this.totalPages = res.data.numberPage;
          }
        } else {
          this.data = null;
          this.dataNum = 0;
        }
        this.isLoading = false;
        this.$forceUpdate();
      });
    },
    downloadAllDataDetailFilter() {
      if (this.dataFilterInput.end_date === "") {
        this.dataFilterInput.end_date = moment(new Date()).format("YYYY-MM-DD");
      }
      if (this.dataFilterInput.start_date === "") {
        this.dataFilterInput.start_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }

      this.waitingDownloadCSVFilter = true;
      axios
        .post(this.urlDownloadDetailCSV, this.dataFilterInput)
        .then((res) => {
          if (res.status == 200) {
            this.waitingDownloadCSVFilter = false;
            let url_dowload =
              Const.ROOT_API +
              Const.SUBMIT_PATH +
              Const.DOWNLOAD_FILE +
              "?url=" +
              res.data.url;
            window.open(url_dowload);
          } else {
            let mess = res.data.message;
            alert(mess);
          }
        });
    },
    downloadImageFilter() {
      if (this.dataFilterInput.end_date === "") {
        this.dataFilterInput.end_date = moment(new Date()).format("YYYY-MM-DD");
      }
      if (this.dataFilterInput.start_date === "") {
        this.dataFilterInput.start_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }
      this.waitingDownloadIMG = true;
      if (!this.data || this.data.length === 0) {
        return (this.waitingDownloadIMG = false);
      }
      axios
        .post(this.urlDownloadDetailIMG, this.dataFilterInput)
        .then((res) => {
          if (res.status == 200) {
            this.waitingDownloadIMG = false;
            if (!res.data.err) {
              let url_dowload =
                Const.ROOT_API +
                Const.SUBMIT_PATH +
                Const.DOWNLOAD_FILE +
                "?url=" +
                res.data.url;

              console.log(url_dowload);
              window.open(url_dowload);
            }
            else{
              this.messageError = "ダウンロード対象の容量が大きすぎるため、ダウンロードができません。対象を選択しなおしてください。"
              this.$bvModal.show("modal-image-error")
            }

            // alert("download done");
          } else {
            let mess = res.data.message;
            alert(mess);
          }
        })
        .catch(() => {
          return (this.waitingDownloadIMG = false);
        });
    },
    // deleteSettingVideoText
    showItem(item) {
      this.isShowDetailSafety = true;
      this.dataModal = item;
    },
    openEvent(item) {
      this.dataModal = item;
      // this.isShowDetailEvent = true;

      this.$bvModal.show("modal-info-detail");
    },
    onShowModalImage(item) {
      const itempTemp = { ...item };

      itempTemp.file_image = JSON.parse(itempTemp.file_image);
      this.dataImage = itempTemp;
      console.log(this.dataImage.file_image);
      this.$bvModal.show("show-img");
    },
  },
};
</script>
<style
  scoped
  src="@/assets/css/TP_AD1/TPAD1_List-Safety-Confirmation.css"
></style>
