<template>
  <div class="div-all-list">
    <Menu :isFirst_2="true"></Menu>
    <div class="content">
      <LoadingData v-if="isLoading"></LoadingData>
      <div>
        <!-- <div
          v-bind:class="['div-find', this.isLoading === true ? 'disable' : '']"
        >
          <DivFilter
            @dataFilter="handleEventFilter"
            :openDivFilter="showFillter"
            @openDivFillter="openDivFillter"
            :dataFilterInput="dataFilterInput"
          ></DivFilter>
        </div> -->
        <div class="div-body-content" v-if="!isLoading">
          <div class="top-body-content">
            <div class="div-action-page">
              <div class="action-left">
                <div class="total-list" v-bind:title="'閲覧確認一覧'">
                  <span>&ensp;安否確認一覧</span>
                </div>
                <div class="total-rate">
                  <span class="text-number">{{
                    formatThreeDigitAfterComma(dataNum)
                  }}</span>
                  <span>&ensp;件</span>
                </div>
              </div>
            </div>
          </div>
          <div class="div-data-table">
            <div>
              <div class="header-table">
                <div class="name" v-bind:title="'title'"></div>
                <div
                  class="event_detail event_detail-head"
                  v-bind:title="'災害詳細'"
                >
                  災害詳細
                </div>
                <div class="after_hour_resend" v-bind:title="'時間設定'">
                  時間設定
                </div>
                <div class="resend-time" v-bind:title="'最大回数'">
                  最大回数
                </div>
                <div class="day-resend" v-bind:title="'回答期限'">回答期限</div>
              </div>
            </div>

            <!-- Search data does not exist -->
            <FilterNotData
              v-if="this.data == 0"
              class="reward-list-nothing"
              @showDivFilter="openDivFillter"
              :isEventNoData="true"
            ></FilterNotData>

            <!-- Search data exists -->
            <div
              id="content-table"
              class="row-content-table"
              v-if="this.data != 0"
            >
              <div
                class="div-table-row poitier-mouse"
                v-for="(item, index) in data"
                :key="index"
                v-bind:id="['row-' + index]"
              >
                <div
                  class="name name-item"
                  v-b-modal.modal-info
                  @click="showItem(item, index)"
                >
                  {{ item.name || "" }}
                </div>

                <div class="event_detail">
                  {{ item.event_detail || "" }}
                </div>
                <div class="after_hour_resend">
                <!-- <div class="after_hour_resend" v-bind:style="item.after_hour_resend &lt; 0? styleRed :{}"> -->
                  {{ (item.after_hour_resend &lt; 0) ? item.after_hour_resend_default:item.after_hour_resend }}
                  h
                </div>
                <div class="resend-time" >
                <!-- <div class="resend-time" v-bind:style="item.resend_times &lt; 0? styleRed:{}"> -->
                  {{ (item.resend_times &lt; 0) ? item.resend_times_default :item.resend_times }}
                  回
                </div>
                <div class="day-resend day-resend-item">
                <!-- <div class="day-resend day-resend-item" v-bind:style="item.day_expire &lt; 0? styleRed:{}"> -->
                  {{
                    `${item.day_expire &lt; 0 ? (item.day_expire_default === 0 ? "なし" :item.target_day_expire_default) : (item.day_expire === 0?"なし" : item.target_day_expire)}`
                  }}
                </div>
              </div>
            </div>
            <div class="sellect-number-paginate" v-if="this.data != 0">
              <PaginateComponent
                :currentPage="this.currentPage"
                :limit="this.limitItem"
                :totalPages="this.totalPages"
                @pagechanged="reloadPage"
              ></PaginateComponent>
            </div>
          </div>
        </div>
        <DetailEvent
          v-if="isShowDetailEvent"
          :dataModal="dataModal"
          :functionChange="functionOnChange"
          @onClose="() => (isShowDetailEvent = false)"
        ></DetailEvent>
        <ShowImageModal :dataImage="dataImage"></ShowImageModal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Const from "../../constant/constant.js";
import Menu from "../../components/common/AdminMenu_Comp.vue";
import { FormatThreeDigitAfterComma } from "../../assets/js/common/FormatThreeDigitAfterComma";
// import DivFilter from "@/components/TP_AD1/TPAD1_Filter_Comp.vue";
import FilterNotData from "@/components/TP_AD1/TPAD1_FilterNotData.vue";
import DetailEvent from "@/components/TP_AD1/TPAD1_DetailEvent.vue";
import PaginateComponent from "../../components/common/PaginateComponent.vue";
import LoadingData from "../../components/common/LoadingData_Comp";
import { DateFormat } from "../../assets/js/common/DateFormat";
import ShowImageModal from "@/components/TP_AD1/TPAD1_ShowImage_Modal.vue";

export default {
  components: {
    Menu,
    // DivFilter,
    DetailEvent,
    PaginateComponent,
    LoadingData,
    ShowImageModal,
    FilterNotData,
  },
  name: "ListSafetyConfirmation",
  data: function () {
    return {
      urlGetListConfirm: Const.ROOT_API + Const.EVENT_PATH + Const.GET_EVENT,
      urlDownloadCSV:
        Const.ROOT_API + Const.SUBMIT_PATH + Const.DOWLOAD_ALL_CSV,
      urlDownloadDetailCSV:
        Const.ROOT_API + Const.SUBMIT_PATH + Const.DOWNLOAD_DETAIL_CSV,
      urlAPIFilterData: Const.ROOT_API + Const.EVENT_PATH + Const.GET_EVENT,
      dataModal: {},
      arrayList: 0,
      arrayListSelect: 0,
      waitingDownloadDetail: false,
      waitingDownloadCSVFilter: false,
      // openDivFilter: false,
      showFillter: false,
      listVideo: [],
      isLoading: true,
      data: [],
      default: [],
      totalPages: 0,
      currentPage: 1,
      limitItem: 20,
      dataNum: 0,
      filtering: false,
      styleRed: {color:"red"},
      dataFilterInput: {
        therapist_no: "",
        shop_name: "",
        shop_area: "",
        is_working: 0,
        last_work_date: "",
        event_id: 0,
        event_type: "",
        is_auto_send: 0,
        is_answer: 0,
        state_of_health: 0,
        is_can_work: 0,
        state_of_shop: 0,
        file_image: 0,
        comment: 0,
        limit: 20,
        page: 1,
        start_date: "",
        end_date: "",
      },
      functionOnChange: this.changeRow,

      dataImage: {},
      startDate: "",
      endDate: "",
      //
      isShowDetailEvent: false,
    };
  },
  mixins: [FormatThreeDigitAfterComma, DateFormat],
  created() {
    console.log(typeof this.functionOnChange);
  },
  mounted() {
    localStorage.type_login = "tp_weather";
    this.urlGetListConfirm +=
      "?limit=" + this.limitItem + "&page=" + this.currentPage;
    this.postDataFilter(this.urlGetListConfirm);
  },
  methods: {
    /*
     * Call API
     *
     * @param url (Url API)
     */
    getData: function (url) {
      axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data.data;
            if (res.data.numberPage != 0) {
              this.totalPages = res.data.numberPage;
              this.dataNum = res.data.length;
            } else {
              this.dataNum = 0;
            }
          } else {
            this.data = null;
            this.dataNum = 0;
            this.totalPages = 0;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          return err;
        });
    },
    // show div filter
    openDivFillter() {
      this.showFillter = !this.showFillter;
    },
    handleEventFilter: function (data) {
      this.isLoading = true;

      this.currentPage = 1;
      this.dataFilterInput = data;
      this.dataFilterInput.page = 1;
      this.limitItem = this.dataFilterInput.limit;

      this.$forceUpdate();
      this.filtering = true;
      this.postDataFilter(
        this.urlAPIFilterData,
        this.dataFilterInput,
        false,
        false
      );
    },
    reloadPage: function (data) {
      this.isLoading = true;
      this.currentPage = data.page;
      // check has fillter
      this.dataFilterInput.page = data.page;

      if (this.dataFilterInput.limit != data.limit) {
        this.dataFilterInput.page = 1;
        this.currentPage = 1;
        var changeLimit = true;
      }
      this.dataFilterInput.limit = data.limit;
      this.limitItem = data.limit;
      this.postDataFilter(
        this.urlAPIFilterData,
        this.dataFilterInput,
        true,
        changeLimit
      );
    },
    postDataFilter: function (url, body, isNextPage, changeLimit) {
      axios.post(url, body).then((res) => {
        if (res.status == 200) {
          this.data = res.data.data;
          if (!isNextPage || changeLimit) {
            this.dataNum = res.data.length;
            this.totalPages = res.data.numberPage;
          }
        } else {
          this.data = null;
          this.dataNum = 0;
        }
        this.isLoading = false;
        this.$forceUpdate();
      });
    },

    // deleteSettingVideoText
    showItem(item, index) {
      console.log(item);
      this.dataModal = {
        ...item,
        index,
      };
      this.isShowDetailEvent = true;
    },
    changeRow(data, index) {
      console.log(data);
      this.data[index].after_hour_resend = data.after_hour_resend;
      this.data[index].resend_times = data.resend_times;
      this.data[index].is_expire = data.is_expire;
      this.data[index].target_day_expire = data.target_day_expire;
      this.data[index].day_expire = data.day_expire;
    },
  },
};
</script>
<style scoped src="@/assets/css/TP_AD1/TPAD1_List-Event.css"></style>
