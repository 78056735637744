<template>
  <div class="reward-list-nothing">
    <img
      class="icon-reward-list-nothing"
      src="@/assets/img/common/simple-facial-expression-icon-4.svg"
      alt="reward-list-nothing"
    />
    <span class="no-result" v-if="!this.isEventNoData">検索条件に一致する結果は見つかりませんでした…</span>
    <span class="no-result" v-if="this.isEventNoData">一致する結果は見つかりませんでした</span>
    <span class="open-div-filter poitier-mouse"  v-if="!this.isEventNoData" @click="actionDivFilter()"
      >もう一度検索する</span
    >
  </div>
</template>


<script>
export default {
  data() {
    return {
      openDivFilter: false,
    };
  },
  props: {
    isEventNoData: Boolean
  },
  methods: {
    //send logic show hide div filter
    actionDivFilter() {
      this.openDivFilter = !this.openDivFilter;
      this.$emit("showDivFilter", this.openDivFilter);
    },
  },
};
</script>
<style scoped src="@/assets/css/TP_AD1/TPAD1_FilterNotData.css"></style>