export const DateFormat = {
  methods: {
    // string format(date with commans)
    dateFormat(x) {
      if (x == null || typeof x === 'undefined' || x == '') {
        return '';
      }

      const date = new Date(x);
      var d = date.getUTCDate();
      var m = date.getUTCMonth() + 1;
      return `${date.getUTCFullYear()}/${(m <= 9 ? '0' + m : m)}/${(d <= 9 ? '0' + d : d)}`;
    },

    dateTimeFormat(x) {
      if (x == null || typeof x === 'undefined' || x == '') {
        return '';
      }
      const date = new Date(x);
      var d = date.getUTCDate();
      var m = date.getUTCMonth() + 1;
      var h = date.getUTCHours();
      var i = date.getUTCMinutes();
      var s = date.getUTCSeconds();

      return `${date.getUTCFullYear()}/${(m <= 9 ? '0' + m : m)}/${(d <= 9 ? '0' + d : d)} ${(h <= 9 ? '0' + h : h)}:${(i <= 9 ? '0' + i : i)}:${(s <= 9 ? '0' + s : s)}`;
    }
  }
};
