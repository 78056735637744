<template>
  <div class="not-permission">
    <div>
      <img
        class="icon-not-permission"
        src="@/assets/img/common/simple-facial-expression-icon-4.svg"
        alt="reward-list-nothing"
      />
    </div>
    <span class="no-result">
      アクセスの権限がありません<br />
      管理者に連絡してください</span
    >
  </div>
</template>


<script>
export default {
  name: "error-page",
  data() {
    return {};
  },
  mounted() {
    document.title = "ERROR";
  },
};
</script>
<style scoped src="@/assets/css/TP_Login/TP_Login.css"></style>