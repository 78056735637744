<template>
  <b-modal id="modal-info" hide-footer no-close-on-backdrop @hidden="onClose">
    <div class="div-data-table">
      <div class="div-detail">
        <div class="detail-content row">
          <div class="title">セラピストNo</div>
          <div class="text-value">{{ dataProps.therapist_no }}</div>
        </div>
        <div class="detail-content row">
          <div class="title">セラピスト名</div>
          <div class="text-value">{{ dataProps.therapist_name }}</div>
        </div>
        <div class="detail-content row">
          <div class="title">メールアドレス</div>
          <div class="text-value">{{ dataProps.contractor_email }}</div>
        </div>
        <div class="detail-content row">
          <div class="title">電話番号</div>
          <div class="text-value">{{ dataProps.contractor_tel }}</div>
        </div>
        <div class="detail-content row">
          <div class="title">住所</div>
          <div class="text-value">{{ dataProps.contractor_address }}</div>
        </div>
        <div class="row-line row">
          <div class="div-comment-left">コメント機能</div>
          <div
            class="div-comment-right"
            v-html="dataProps.therapist_comment"
            v-if="
              dataProps.therapist_comment !== '' &&
              dataProps.therapist_comment !== null
            "
          >
            {{ dataProps.therapist_comment }}
          </div>
        </div>
        <div class="div-btn-add">
          <button
            type="button"
            class="btn button-register"
            v-b-modal.add-edit-text
            @click="showEditer"
          >
            {{
              dataProps.therapist_comment === "" ||
              dataProps.therapist_comment === null
                ? "備考追加"
                : "編集"
            }}
          </button>
        </div>
      </div>
      <ModalSuccess :notificationContent="notificationSuccess"></ModalSuccess>
      <TextEditer
        v-if="isShowTextEditer"
        :dataProps="dataProps"
        @onCloseEditText="() => (isShowTextEditer = false)"
      ></TextEditer>
    </div>
  </b-modal>
</template>

<script>
// Define component
import axios from "axios";
import Const from "../../constant/constant.js";
import ModalSuccess from "../../components/common/Modal_Success.vue";
import TextEditer from "@/components/TP_AD1/TPAD1_TextEditer.vue";

export default {
  components: {
    ModalSuccess,
    TextEditer,
  },
  props: {
    dataModal: {
      type: Object,
    },
  },
  data: function () {
    return {
      notificationSuccess: "",
      dataProps: {},
      waiting: false,
      listTimeExport: [],
      listTimeExportTemp: [],
      isShowTextEditer: false,
    };
  },
  created() {
    this.dataProps = { ...this.dataModal };
  },
  methods: {
    onClickAddOrUpdate() {
      this.waiting = true;
      let urlInsertComment =
        Const.ROOT_API + Const.SUBMIT_PATH + Const.ADD_COMMENT;
      let paramInsert = {
        therapist_id: this.dataProps.therapist_id,
        therapist_comment: this.dataProps.therapist_comment,
      };
      axios
        .put(urlInsertComment, paramInsert)
        .then((res) => {
          this.dataModal.therapist_comment = this.dataProps.therapist_comment;
          this.waiting = false;
          if (res.data.status_code == 200) {
            this.notificationSuccess = "Update Success!";
            this.$bvModal.show("modal-noti-success");
          }
        })
        .catch((err) => {
          return err;
        });
    },

    // Event hide modal
    onHideModal() {
      this.listTimeExport = this.listTimeExportTemp.map((item) => {
        return {
          ...item,
        };
      });
      this.$bvModal.hide("modal-info");
    },
    onClose() {
      this.$emit("onClose");
    },

    showEditer() {
      this.isShowTextEditer = true;
    },
  },
};
</script>

<!-- Import style file -->
<style scoped src="@/assets/css/TP_AD1/TPAD1_DetailSafety.css"></style>
<style scoped src="vue2-editor/dist/vue2-editor.css"></style>
<style scoped>
::v-deep .modal-content {
  background-color: #f5f5f5 !important;
  width: 90%;
  bottom: 150px;
  right: 8px;
  height: 550px;
  width: 750px;
}

::v-deep .mx-input {
  margin-left: 34px;
  padding-left: 30px;
  text-align: center !important;
}
::v-deep .modal-header {
  padding: 0.15rem 0.25rem;
}
</style>
<style scoped>
#editor {
  height: 220px;
}
::v-deep .ql-editor {
  min-height: 170px !important;
  font-size: 14px;
  background-color: white;
}
</style>


