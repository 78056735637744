export const FormatThreeDigitAfterComma = {
  methods: {
    // string format(number with commans)
    formatThreeDigitAfterComma(x) {
      if (x == null || typeof x === "undefined") {
        return 0;
      } else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
};
