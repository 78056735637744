<template>
  <b-modal
    id="add-edit-text"
    hide-footer
    no-close-on-backdrop
    @hidden="onCloseEditText"
  >
    <div class="detail-content row">
      <div class="title">備考内容</div>
      <div class="text-value">
        <div>
          <vue-editor
            id="editor"
            :editorToolbar="customToolbar"
            v-model="dataTextEdit.therapist_comment"
          >
          </vue-editor>
        </div>
      </div>
    </div>
    <div class="div-btn row">
      <button type="button" class="btn button-cancel" @click="onClickCanCel()">
        キャンセル
      </button>
      <button
        type="button"
        class="btn button-add"
        @click="onClickAddOrUpdate()"
      >
        <img
          v-if="waiting"
          class="icon-load"
          src="@/assets/img/common/loadingdot.gif"
          alt="loading"
        />
        {{ dataTextEdit.therapist_comment === "" ? "備考追加" : "編集" }}
      </button>
    </div>
    <ModalSuccess :notificationContent="notificationSuccess"></ModalSuccess>
  </b-modal>
</template>

<script>
// Define component
import axios from "axios";
import Const from "../../constant/constant.js";
import { VueEditor } from "vue2-editor";
import ModalSuccess from "../../components/common/Modal_Success.vue";

export default {
  components: {
    VueEditor,
    ModalSuccess,
  },
  props: {
    dataUpdate: {
      type: Object,
      default() {
        return {
          therapist_comment: "",
        };
      },
    },
    dataProps: {
      type: Object,
    },
  },
  data: function () {
    return {
      notificationSuccess: "",
      isModelAdd: false,
      waiting: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      dataTextEdit: {},
    };
  },
  created() {
    this.dataTextEdit = { ...this.dataProps };
  },
  methods: {
    // Event click button Cancel
    onClickCanCel() {
      this.$bvModal.hide("add-edit-text");
    },

    // Event click button Add/Update
    onClickAddOrUpdate() {
      this.waiting = true;
      let urlInsertComment =
        Const.ROOT_API + Const.SUBMIT_PATH + Const.ADD_COMMENT;
      let paramInsert = {
        therapist_id: this.dataTextEdit.therapist_id,
        therapist_comment: this.dataTextEdit.therapist_comment,
      };
      axios
        .put(urlInsertComment, paramInsert)
        .then((res) => {
          this.dataProps.therapist_comment = this.dataTextEdit.therapist_comment;
          this.waiting = false;
          if (res.data.status_code == 200) {
            this.$bvModal.hide("add-edit-text");
          }
        })
        .catch((err) => {
          return err;
        });
    },
    onCloseEditText() {
      this.$emit("onCloseEditText");
    },
  },
};
</script>

<style scoped src="@/assets/css/TP_AD1/TPAD1_DetailSafety.css"></style>
<style scoped src="vue2-editor/dist/vue2-editor.css"></style>
<style scoped>
#editor {
  height: 220px;
}
::v-deep .ql-editor {
  min-height: 140px !important;
  font-size: 16px;
}
::v-deep .modal-content {
  background-color: #ffffff !important;
  width: 90%;
  bottom: 72px;
  right: 8px;
  height: 312px;
  width: 750px;
}
::v-deep .mx-input {
  margin-left: 34px;
  padding-left: 30px;
  text-align: center !important;
}
::v-deep .modal-header {
  padding: 0.15rem 0.25rem;
}
</style>
