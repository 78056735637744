<template>
  <div class="div-all-list">
    <Menu :isFirst_3="true"></Menu>
    <div class="content">
      <div class="title">一斉送信</div>

      <div class="row-line-title">
        <div class="row-line-left">
          宛先条件 <span class="icon-required">*</span>
        </div>
        <div class="div-checkbox">
          <input
            type="checkbox"
            v-model="isSendAll"
            @change="changeEventSendAll($event)"
          />
          <span style="margin-left: 10px">契約中セラピスト一括送信</span>
        </div>
      </div>
      <!-- line 1 condition -->
      <div class="row-line-title">
        <div class="row-line-left"></div>
        <div class="div-condition">
          <div class="condition-130 w-event-date" style="width: 260px">
            日付選択
          </div>
          <div class="condition-130 w-event-name">発報情報</div>
        </div>
      </div>

      <div style="display: flex">
        <div class="row-line-left"></div>
        <div class="div-condition">
          <div
            :disabled="isSendAll"
            v-bind:class="[
              isSendAll ? 'mouse-disabl-date' : 'condition-130 w-event-date',
            ]"
          >
            <div>
              <PickStartDateComp
                ref="myDatepicker"
                @onChangeDate="handleEventDate"
                v-model="eventDate"
              ></PickStartDateComp>
            </div>
          </div>

          <div v-bind:class="[isSendAll ? 'mouse-disable-1' : 'eventName']">
            <select
              :disabled="isSendAll"
              v-bind:class="[
                isSendAll ? 'div-selected-1' : 'div-selected poitier-mouse',
              ]"
              v-model="event_detail_name"
              @change="handleEventDetail"
            >
              <option
                class="poitier-mouse"
                v-for="(event, index) in this.list_event_detail_name"
                :key="index"
                v-bind:name="event.name"
              >
                {{ event.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- line 2 condition -->
      <div class="row-line-title">
        <div class="row-line-left"></div>
        <div class="div-condition">
          <div style="width: 150px">TP住所都道府県</div>
          <div class="condition-130">当日業務</div>
        </div>
      </div>
      <div style="display: flex">
        <div class="row-line-left"></div>
        <div class="div-condition">
          <div
            style="width: 150px"
            v-bind:class="[
              isSendAll || isSelectEventName ? 'mouse-disable' : '',
            ]"
          >
            <multiselect
              :disabled="isSendAll || isSelectEventName"
              :multiple="true"
              :close-on-select="false"
              :options="listArea"
              :show-labels="false"
              v-model="listAreaSelected"
              placeholder=""
              :limitText="limitText"
              @input="handleInput"
              :allow-empty="true"
            >
              <span slot="noResult"
                >Oops! No elements found. Consider changing the search
                query.</span
              >
            </multiselect>
          </div>
          <div
            class="condition-130"
            v-bind:class="[
              isSendAll || isSelectEventName ? 'mouse-disable' : '',
            ]"
          >
            <multiselect
              :disabled="isSendAll || isSelectEventName"
              :multiple="false"
              :options="optionGoToWork"
              :show-labels="false"
              v-model="selectGoToWork"
              placeholder=""
            >
            </multiselect>
          </div>
        </div>
      </div>

      <!-- line 3 condition -->
      <div class="row-line-title">
        <div class="row-line-left"></div>
        <div class="div-condition">
          <div style="width: 150px">回答有無</div>
          <div class="condition-130">けがの状況</div>
          <div class="condition-130">業務可否</div>
          <div class="condition-130">店舗状況</div>
        </div>
      </div>
      <div style="display: flex">
        <div class="row-line-left"></div>
        <div class="div-condition">
          <div
            class="w-is-answer"
            style="width: 150px"
            v-bind:class="[
              isSendAll || isSelectEventName ? 'mouse-disable' : '',
            ]"
          >
            <multiselect
              :disabled="isSendAll || isSelectEventName"
              :multiple="false"
              :options="optionAnswer"
              :show-labels="false"
              v-model="selectAnswer"
              placeholder=""
            >
            </multiselect>
          </div>
          <div
            class="condition-130"
            v-bind:class="[
              isSendAll || isSelectEventName ? 'mouse-disable' : '',
            ]"
          >
            <multiselect
              :disabled="isSendAll || isSelectEventName"
              :multiple="false"
              :options="healthStatus"
              :show-labels="false"
              v-model="selectHealthStatus"
              placeholder=""
            >
            </multiselect>
          </div>
          <div
            class="condition-130"
            v-bind:class="[
              isSendAll || isSelectEventName ? 'mouse-disable' : '',
            ]"
          >
            <multiselect
              :disabled="isSendAll || isSelectEventName"
              :multiple="false"
              :options="optionCanWork"
              :show-labels="false"
              v-model="selectCanWork"
              placeholder=""
            >
            </multiselect>
          </div>
          <div
            class="condition-130"
            v-bind:class="[
              isSendAll || isSelectEventName ? 'mouse-disable' : '',
            ]"
          >
            <multiselect
              :disabled="isSendAll || isSelectEventName"
              :multiple="false"
              :options="listStatusShop"
              :show-labels="false"
              v-model="selectStatusShop"
              placeholder=""
            >
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row-line-1">
        <div class="row-line-left">セラピストNo</div>
        <div class="div-title">
          <b-input
            :disabled="isSendAll"
            v-model="listTPNoStr"
            v-bind:class="[isSendAll ? 'mouse-disable' : '']"
          />
        </div>
      </div>
      <div class="row-line-2">
        <div class="row-line-left">
          <span class="title-mail">表題</span>
          <span class="icon-required">*</span> <br />
          （メール)
        </div>
        <div class="div-title">
          <b-input v-model="titleNotify" />
        </div>
      </div>

      <div class="row-line-2" style="margin-top: 17px">
        <div class="row-line-left">
          <span class="title-mail">本文</span
          ><span class="icon-required">*</span> <br />(メール·LINE)
        </div>
        <div class="div-title-1">
          <b-textarea v-model="contentNotify" rows="10"></b-textarea>
        </div>
      </div>
      <div class="row-line-5">
        <div class="row-line-left"></div>
        <div class="div-note">
          ※本文についてはメール·LINEに同じ内容が送信されます。
        </div>
      </div>
      <div class="row-line-title">
        <div class="row-line-push"></div>
        <div style="padding-left: 100px; font-size: 18px">Push通知内容</div>
      </div>

      <div class="row-line-3">
        <div class="row-line-left"></div>
        <div class="div-title-1">
          <b-textarea
            v-model="push_content"
            maxlength="30"
            rows="5"
          ></b-textarea>
        </div>
      </div>
      <div class="row-line-5">
        <div class="row-line-left"></div>
        <div class="div-note">※30 文字までで入力してください。</div>
      </div>

      <div class="row-line-6">
        <div class="row-line-left"></div>
        <div class="div-btn">
          <button
            @click="onClickBtnCancel()"
            type="button"
            class="btn button-cancel"
          >
            クリアー
          </button>
          <button
            type="button"
            class="btn button-register"
            @click="onSubmitSendNotification()"
          >
            <img
              v-if="waiting"
              class="icon-load"
              src="@/assets/img/common/loadingdot.gif"
              alt="loading"
            />
            送信
          </button>
        </div>
      </div>
    </div>
    <ModalNotification
      :notificationContent="notificationContent"
    ></ModalNotification>
    <ModalFail :notificationContent="notificationFail"></ModalFail>
  </div>
</template>
<script>
import Menu from "../../../src/components/common/AdminMenu_Comp.vue";
import Multiselect from "vue-multiselect";
import Const from "../../constant/constant.js";
import axios from "axios";
import ModalNotification from "../../components/common/Modal_Success.vue";
import ModalFail from "../../components/common/Modal_Fail.vue";
import moment from "moment";
import PickStartDateComp from "@/components/common/DatePicker_Comp.vue";
export default {
  components: {
    Multiselect,
    Menu,
    ModalNotification,
    ModalFail,
    PickStartDateComp,
  },
  data() {
    return {
      selected: [],
      options: ["list", "of", "options"],
      listTPNoSelected: [],
      listTPNo: ["0000058", "0000059", "0000060", "0000061"],
      listAreaSelected: [],
      listArea: [],
      optionGoToWork: ["", "有", "無"],
      selectGoToWork: "",
      optionAnswer: ["", "有", "無"],
      selectAnswer: "",
      healthStatus: ["", "無", "軽傷", "重症"],
      selectHealthStatus: "",
      optionCanWork: ["", "可", "否"],
      selectCanWork: "",
      listStatusShop: ["", "営業可", "営業不可", "不明"],
      selectStatusShop: "",
      listSendMethod: {
        isSendMail: true,
        isSendNotify: false,
        isSendLine: false,
      },
      listSendMethodSelected: [],
      isSendAll: false,
      listTPNoStr: "",
      titleNotify: "",
      contentNotify: "",
      isReply: false,
      notificationContent: "通知を送信しました。",
      notificationFail: "",
      waiting: false,
      listAreaTemp: [],
      list_event_detail_name: [],
      event_detail_name: "",
      eventDate: new Date(),
      event_type: "",
      event_id: 0,
      push_content: "",
      push_title: "",
      isSelectEventName: true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
    };
  },
  created() {
    localStorage.type_login = "tp_weather";
    this.getListPrefecture();
    let currentDate = new Date();
    let body = {
      occured_date: moment(currentDate).format("YYYY-MM-DD"),
    };
    this.getDataEventName(body);
  },
  methods: {
    handleInput(event) {
      const selectAll = event.find((item) => item === "Select All");
      const emptySelected = event.find((item) => item === "");
      if (emptySelected === "") {
        this.listAreaSelected = [];
      }
      if (selectAll === "Select All") {
        let arrayTemp = [...this.listArea];
        arrayTemp = arrayTemp.filter((item) => item && item !== "Select All");
        this.listAreaSelected = this.listAreaSelected.filter(
          (item) => item && item !== "Select All"
        );
        if (this.listAreaSelected.length === arrayTemp.length) {
          this.listAreaSelected = [];
        } else {
          this.listAreaSelected = arrayTemp;
        }
      }
    },
    limitText(count) {
      return `and ${count} other countries`;
    },
    changeEventSendAll(event) {
      if (event.target.checked) {
        this.listAreaSelected = [];
        this.selectGoToWork = "";
        this.selectAnswer = "";
        this.selectHealthStatus = "";
        this.selectCanWork = "";
        this.selectStatusShop = "";
        this.listTPNoStr = "";
        this.event_detail_name = "";
        this.event_type = "";
        this.event_id = 0;
        this.$refs.myDatepicker.setDisabledComponentDatePicker();
      } else {
        this.$refs.myDatepicker.clearDisabledComponentDatePicker();
        this.isSelectEventName = true;
        this.eventDate = new Date();
        let body = {
          occured_date: moment(this.eventDate).format("YYYY-MM-DD"),
        };
        this.getDataEventName(body);
      }
    },
    onSubmitSendNotification() {
      let isWorkingParam = 0;
      let isAnswerParam = 0;
      let stateOfHealthParam = 0;
      let isCanWorkParam = 0;
      let stateOfShopParam = 0;
      let listTPNo = this.listTPNoStr.trim().split(",");
      let listTPNoParam = [...listTPNo];
      if (listTPNoParam.length === 1 && listTPNoParam[0] === "") {
        listTPNoParam = [];
      }
      switch (this.selectGoToWork) {
        case Const.STRING_EMPTY:
          isWorkingParam = 0;
          break;
        case "有":
          isWorkingParam = 1;
          break;
        case "無":
          isWorkingParam = 2;
      }
      switch (this.selectAnswer) {
        case Const.STRING_EMPTY:
          isAnswerParam = 0;
          break;
        case "有":
          isAnswerParam = 1;
          break;
        case "無":
          isAnswerParam = 2;
      }
      switch (this.selectHealthStatus) {
        case Const.STRING_EMPTY:
          stateOfHealthParam = 0;
          break;
        case "無":
          stateOfHealthParam = 1;
          break;
        case "軽傷":
          stateOfHealthParam = 2;
          break;
        case "重症":
          stateOfHealthParam = 3;
      }
      switch (this.selectCanWork) {
        case Const.STRING_EMPTY:
          isCanWorkParam = 0;
          break;
        case "可":
          isCanWorkParam = 1;
          break;
        case "否":
          isCanWorkParam = 2;
      }
      switch (this.selectStatusShop) {
        case Const.STRING_EMPTY:
          stateOfShopParam = 0;
          break;
        case "営業可":
          stateOfShopParam = 1;
          break;
        case "営業不可":
          stateOfShopParam = 2;
          break;
        case "不明":
          stateOfShopParam = 3;
      }
      let listAreaSelectedTemp = this.listAreaSelected.map((ele) => {
        let areaId = this.listAreaTemp.find((eleArea) => eleArea.name === ele);
        return areaId.id;
      });

      let event_date = this.eventDate
        ? moment(this.eventDate).format("YYYY-MM-DD")
        : "";
      let param = {
        is_select_all: this.isSendAll,
        pref_code: listAreaSelectedTemp,
        is_working: isWorkingParam,
        is_answer: isAnswerParam,
        state_of_health: stateOfHealthParam,
        is_can_work: isCanWorkParam,
        state_of_shop: stateOfShopParam,
        tp_no: listTPNoParam,
        title: this.titleNotify,
        body: this.contentNotify,
        event_date: event_date,
        event_type: this.event_type,
        event_name: this.event_name,
        push_title: this.push_title,
        push_content: this.push_content,
      };

      let url =
        Const.ROOT_API +
        Const.ADMIN_PATH +
        Const.NOTIFICATION_SEND +
        Const.SEND_MSG_BY_HAND;
      if (this.checkInputRequired()) {
        this.waiting = true;
        axios
          .post(url, param)
          .then((res) => {
            this.waiting = false;
            if (res.data.message === Const.MSG_SUCCESS) {
              this.$bvModal.show("modal-noti-success");
            }
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$bvModal.show("modal-noti-fail");
      }
    },
    getListPrefecture() {
      let url = Const.ROOT_API + Const.SUBMIT_PATH + Const.GET_DATA_PREFECTURE;
      axios
        .get(url)
        .then((res) => {
          if (res.data.message === Const.MSG_SUCCESS) {
            this.listAreaTemp = res.data.data;
            res.data.data.forEach((element) => {
              this.listArea.push(element.name);
            });
            this.listArea.unshift("");
          }
        })
        .catch((err) => {
          return err;
        });
    },
    checkInputRequired() {
      let isRequired = true;
      if (
        (this.titleNotify.trim() === "" &&
          this.contentNotify.trim() === "" &&
          this.push_content.trim() === "") ||
        (this.titleNotify.trim() === "" && this.contentNotify.trim() !== "") ||
        (this.titleNotify.trim() !== "" && this.contentNotify.trim() == "")
      ) {
        isRequired = false;
        this.notificationFail = "は入力必須項目です";
      }

      return isRequired;
    },
    onClickBtnCancel() {
      this.isSendAll = false;
      this.listAreaSelected = [];
      this.selectGoToWork = "";
      this.selectAnswer = "";
      this.selectHealthStatus = "";
      this.selectCanWork = "";
      this.selectStatusShop = "";
      this.listTPNoStr = "";
      this.titleNotify = "";
      this.contentNotify = "";
      this.event_detail_name = "";
      this.push_title = "";
      this.push_content = "";
      this.event_type = "";
      this.event_id = 0;
      this.isReply = false;
      this.isSelectEventName = true;
      this.$refs.myDatepicker.clearDisabledComponentDatePicker();
      this.eventDate = new Date();
      let body = {
        occured_date: moment(this.eventDate).format("YYYY-MM-DD"),
      };
      this.getDataEventName(body);
    },
    handleEventDate: function (data) {
      this.eventDate = data;
      let param = {
        occured_date: moment(this.eventDate).format("YYYY-MM-DD"),
      };
      this.getDataEventName(param);
    },
    getDataEventName(param) {
      let urlEventName =
        Const.ROOT_API + Const.SUBMIT_PATH + Const.GET_DATA_EVENT_NAME;
      axios
        .post(urlEventName, param)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.list_event_detail_name = res.data.data;
            this.list_event_detail_name.unshift("");
          } else {
            this.isLoading = true;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    handleEventDetail(e) {
      if (e.target.options.selectedIndex > -1) {
        let listEventDetail = this.list_event_detail_name[
          e.target.options.selectedIndex
        ];
        if (
          listEventDetail.name != "" &&
          typeof listEventDetail.name != "undefined"
        ) {
          this.isSelectEventName = false;
        } else {
          this.isSelectEventName = true;
          this.listAreaSelected = [];
          this.selectGoToWork = "";
          this.selectAnswer = "";
          this.selectHealthStatus = "";
          this.selectCanWork = "";
          this.selectStatusShop = "";
        }
        this.event_detail_name = listEventDetail.name;
        if (
          typeof listEventDetail.name == "undefined" ||
          listEventDetail.name == ""
        ) {
          this.event_type = "";
          this.event_name = "";
        } else {
          this.event_type = listEventDetail.event_type;
          this.event_name = listEventDetail.name;
        }
      }
    },
  },
};
</script>
<style scoped src="@/assets/css/TP_AD2/TPAD2_Send-Notification.css"></style>
<style scoped src="vue2-editor/dist/vue2-editor.css"></style>
<style scoped>
#editor {
  height: 220px;
}
textarea {
  resize: none;
}
::v-deep .ql-editor {
  min-height: 220px !important;
  font-size: 16px;
}
::v-deep .div-pick-to-month .mx-input-wrapper .mx-input {
  height: 40px;
  width: 287px;
}
::v-deep .mx-input:disabled {
  background-color: #ededed !important;
}
::v-deep .multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background-color: #e9ecef;
}
</style>
