<template>
  <div class="div-filter">
    <div @click="showFilter()" class="rectangle-filter poitier-mouse">
      <div class="text-filter">{{ valueDefault }}</div>
      <img
        class="icon-filter"
        src="@/assets/img/common/filter.svg"
        alt="Filter"
      />
    </div>
    <div v-if="openDivFilter" class="div-filter-condition">
      <div class="div-row">
        <div class="text-title w-125px">通知日付選択</div>
        <div class="div-right">
          <div class="start-date w-200px">
            <PickStartDateComp
              @onChangeDate="handleStartDate"
              v-model="startDate"
            ></PickStartDateComp>
          </div>
          <div class="w-50px">~</div>
          <div class="end-date w-200px">
            <PickStartDateComp
              @onChangeDate="handleEndDate"
              v-model="endDate"
            ></PickStartDateComp>
          </div>
        </div>
      </div>
      <div class="div-row">
        <div class="text-title w-125px">セラピストNo</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <input
              class="input-id"
              v-model="therapist_no"
              @input="setTherapistNo"
              @change="onChangeTherapistNo()"
            />
          </div>
          <div class="w-65px"></div>
          <div class="text-title w-80px">店舗No</div>
          <div class="div-value w-200px">
            <input
              class="input-id"
              v-model="shop_no"
              @input="setShopNo"
              @change="onChangeShopNo()"
            />
          </div>
        </div>
      </div>

      <div class="div-row">
        <div class="text-title w-125px">TP住所都道府県</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="tp_prefecture"
              @change="handleTPPrefecture"
            >
              <option
                class="poitier-mouse"
                v-for="(tpprefer, index) in this.list_tp_prefecture"
                :key="index"
                v-bind:name="tpprefer.name"
              >
                {{ tpprefer.name }}
              </option>
            </select>
          </div>
          <div class="w-65px"></div>
          <div class="text-title w-80px">店舗エリア</div>
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="shop_area"
              @change="handleShopArea"
            >
              <option
                class="poitier-mouse"
                v-for="(area, index) in this.list_area"
                :key="index"
                v-bind:name="area.name"
              >
                {{ area.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="div-row">
        <div class="text-title w-125px">回答有無</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="is_answer"
              @change="handleIsAnswer"
            >
              <option
                class="poitier-mouse"
                v-for="(prefectures, index) in this.list_is_answer"
                :key="index"
                v-bind:name="prefectures.name"
              >
                {{ prefectures.name }}
              </option>
            </select>
          </div>
          <div class="w-65px"></div>
          <div class="text-title w-80px">店舗都道府県</div>
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="shop_prefecture"
              @change="handlePrefecture"
            >
              <option
                class="poitier-mouse"
                v-for="(prefecture, index) in list_prefectures"
                :key="index"
                v-bind:name="prefecture.name"
              >
                {{ prefecture.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="div-row">
        <div class="text-title w-125px">業務可否</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="is_can_work"
              @change="handlecanWork"
            >
              <option
                class="poitier-mouse"
                v-for="(canwork, index) in this.list_is_can_work"
                :key="index"
                v-bind:name="canwork.name"
              >
                {{ canwork.name }}
              </option>
            </select>
          </div>
          <div class="w-65px"></div>
          <div class="text-title w-80px">店舗状況</div>
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="state_of_shop"
              @change="handleStateShop"
            >
              <option
                class="poitier-mouse"
                v-for="(stateshop, index) in this.list_state_of_shop"
                :key="index"
                v-bind:name="stateshop.name"
              >
                {{ stateshop.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="div-row">
        <div class="text-title w-125px">けがの状況</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="state_of_health"
              @change="handlestateHealth"
            >
              <option
                class="poitier-mouse"
                v-for="(state, index) in this.list_state_of_health"
                :key="index"
                v-bind:name="state.name"
              >
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="w-65px"></div>
          <div class="text-title w-80px">発報情報</div>
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="event_detail_name"
              @change="handleEventDetail"
            >
              <option
                class="poitier-mouse"
                v-for="(event, index) in this.list_event_detail_name"
                :key="index"
                v-bind:name="event.name"
              >
                {{ event.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="div-row">
        <div class="text-title w-125px">最終業務日</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <PickStartDateComp
              @onChangeDate="handleEvenEndDate"
              v-model="endDateWork"
            ></PickStartDateComp>
          </div>
          <div class="w-65px"></div>
          <div class="text-title w-80px">発報区分</div>
          <div class="div-value w-200px">
            <div class="div-value w-200px">
              <select
                class="div-selected poitier-mouse"
                v-model="is_auto_send"
                @change="handleAutoSend"
              >
                <option
                  class="poitier-mouse"
                  v-for="(file, index) in this.list_auto_send"
                  :key="index"
                  v-bind:name="file.name"
                >
                  {{ file.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="div-row">
        <div class="text-title w-125px">特記事項</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="comment"
              @change="handleComment"
            >
              <option
                class="poitier-mouse"
                v-for="(notices, index) in listNotices"
                :key="index"
                v-bind:name="notices.name"
              >
                {{ notices.name }}
              </option>
            </select>
          </div>
          <div class="w-65px"></div>
          <div class="text-title w-80px">添付ファイル</div>
          <div class="div-value w-200px">
            <div class="div-value w-200px">
              <select
                class="div-selected poitier-mouse"
                v-model="file_image"
                @change="handleFileImage"
              >
                <option
                  class="poitier-mouse"
                  v-for="(file, index) in this.list_file_image"
                  :key="index"
                  v-bind:name="file.name"
                >
                  {{ file.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="div-row">
        <div class="text-title w-125px">当日業務</div>
        <div class="div-right">
          <div class="div-value w-200px">
            <select
              class="div-selected poitier-mouse"
              v-model="is_working"
              @change="handleIsWorking"
            >
              <option
                class="poitier-mouse"
                v-for="(work, index) in this.list_is_working"
                :key="index"
                v-bind:name="work.name"
              >
                {{ work.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="div-button">
        <div
          style="button"
          class="btn-cancel poitier-mouse"
          @click="hideFilter()"
        >
          キャンセル
        </div>
        <div class="btn-search poitier-mouse" v-on:click="searchForFilter()">
          検索
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Define child components
import moment from "moment";
import Const from "../../constant/constant.js";
import axios from "axios";
import PickStartDateComp from "@/components/common/DatePicker_Comp.vue";
import { ConvertFullSizeToHalfSize } from "@/components/common/SORV_ConvertFullSizeToHalfSize.js";

export default {
  data: function () {
    return {
      data: {},
      //text show tag filter
      valueDefault: "検索",
      endDateWork: null,
      eventDate: null,
      therapist_nos: "",
      shop_prefectures: "",
      shop_nos: "",
      is_workings: "",
      state_of_healths: "",
      is_can_works: "",
      state_of_shops: "",
      file_images: "",
      is_auto_sends: "",
      is_answers: "",
      comments: "",
      event_detail_names: "",
      tp_prefecture: "",
      tp_prefectures: "",
      shop_area: "",
      shop_areas: "",

      therapist_no: "",
      shop_no: "",
      shop_prefecture: "",
      is_working: 0,
      last_work_date: null,
      event_detail_name: "",
      is_auto_send: 0,
      is_answer: 0,
      state_of_health: 0,
      is_can_work: 0,
      state_of_shop: 0,
      file_image: 0,
      comment: 0,
      list_is_working: [
        { value: 0, name: "" },
        { value: 1, name: "有" },
        { value: 2, name: "無" },
      ],
      list_file_image: [
        { value: 0, name: "" },
        { value: 1, name: "有" },
        { value: 2, name: "無" },
      ],
      listNotices: [
        { value: 0, name: "" },
        { value: 1, name: "有" },
        { value: 2, name: "無" },
      ],
      list_prefectures: [""],
      list_is_answer: [
        { value: 0, name: "" },
        { value: 1, name: "有" },
        { value: 2, name: "無" },
      ],

      checkWorking: 0,
      check_working: "",
      list_state_of_health: [
        { value: 0, name: "" },
        { value: 1, name: "無" },
        { value: 2, name: "軽傷" },
        { value: 3, name: "重症" },
      ],
      is_submited: 0,
      list_is_can_work: [
        { value: 0, name: "" },
        { value: 1, name: "可" },
        { value: 2, name: "否" },
      ],
      list_auto_send: [
        { value: 0, name: "" },
        { value: 1, name: "自動" },
        { value: 2, name: "手動" },
      ],
      list_state_of_shop: [
        { value: 0, name: "" },
        { value: 1, name: "営業可" },
        { value: 2, name: "営業不可" },
        { value: 3, name: "不明" },
      ],
      list_event_detail_name: [],
      list_area: [],
      list_tp_prefecture: [],
      list_shop_name: [],
      therapistNo: "",
      occured_date: "",
      shopNo: "",

      dataFilter: {
        start_date: "",
        end_date: "",
        shop_area: "",
        therapist_no: "",
        shop_no: "",
        shop_prefecture: "",
        is_working: 0,
        last_work_date: "",
        event_id: 0,
        event_type: "",
        is_auto_send: 0,
        is_answer: 0,
        state_of_health: 0,
        is_can_work: 0,
        state_of_shop: 0,
        file_image: 0,
        comment: 0,
        limit: 20,
        page: 1,
      },

      clearFilter: false,
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  mixins: [ConvertFullSizeToHalfSize],
  // Get data from SORA1_RewardList_View.vue
  props: {
    openDivFilter: {
      type: Boolean,
    },
    dataFilterInput: {
      type: Object,
    },
  },
  components: {
    PickStartDateComp,
  },
  mounted() {
    var day = new Date();
    var currentDate =
      day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate();
    let initStartDate = moment(currentDate).format("YYYY-MM-DD");
    let initEndDate = moment(currentDate).format("YYYY-MM-DD");
    this.getDataTPPrefecture(this.urlGetTPPrefecture);
    this.getDataShopArea(this.urlGetShopArea);
    this.getDataEventName(initStartDate, initEndDate);
  },
  methods: {
    getDataTPPrefecture() {
      let urlGetTPPrefecture =
        Const.ROOT_API + Const.SUBMIT_PATH + Const.GET_LIST_PERFECTURE;
      axios
        .get(urlGetTPPrefecture)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.list_tp_prefecture = res.data.data;
            this.list_prefectures = res.data.data;
            this.list_tp_prefecture.unshift("");
          } else {
            this.isLoading = true;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    getDataShopArea() {
      let urlGetShopArea =
        Const.ROOT_API + Const.SUBMIT_PATH + Const.GET_LIST_AREA;
      axios
        .get(urlGetShopArea)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.list_area = res.data.data;
            this.list_area.unshift("");
          } else {
            this.isLoading = true;
          }
        })
        .catch((err) => {
          return err;
        });
    },

    //show or hide div Filter
    showFilter() {
      this.$emit("openDivFillter");
      if (!this.clearFilter) {
        // this.dataFilter.limit = this.dataFilterInput.limit;
      }
    },

    //hide div Filter
    hideFilter() {
      this.clearData();
      this.$emit("openDivFillter", false);
      this.clearFilter = true;
    },

    //search for filter
    searchForFilter() {
      this.dataFilter.start_date = moment(this.startDate).format("YYYY-MM-DD");
      this.dataFilter.end_date = moment(this.endDate).format("YYYY-MM-DD");
      this.dataFilter.last_work_date = this.endDateWork
        ? moment(this.endDateWork).format("YYYY-MM-DD")
        : "";
      if (this.is_answer === "有") {
        this.dataFilter.is_answer = 1;
      } else if (this.is_answer === "無") {
        this.dataFilter.is_answer = 2;
      } else {
        this.dataFilter.is_answer = 0;
      }
      if (this.is_working === "有") {
        this.dataFilter.is_working = 1;
      } else if (this.is_working === "無") {
        this.dataFilter.is_working = 2;
      } else {
        this.dataFilter.is_working = 0;
      }
      if (this.state_of_health === "無") {
        this.dataFilter.state_of_health = 1;
      } else if (this.state_of_health === "軽傷") {
        this.dataFilter.state_of_health = 2;
      } else if (this.state_of_health === "重症") {
        this.dataFilter.state_of_health = 3;
      } else {
        this.dataFilter.state_of_health = 0;
      }
      if (this.is_can_work === "可") {
        this.dataFilter.is_can_work = 1;
      } else if (this.is_can_work === "否") {
        this.dataFilter.is_can_work = 2;
      } else {
        this.dataFilter.is_can_work = 0;
      }

      // 1: 自動  2: 手動
      if (this.is_auto_send === "自動") {
        this.dataFilter.is_auto_send = 1;
      } else if (this.is_auto_send === "手動") {
        this.dataFilter.is_auto_send = 2;
      } else {
        this.dataFilter.is_auto_send = 0;
      }

      if (this.state_of_shop === "営業可") {
        this.dataFilter.state_of_shop = 1;
      } else if (this.state_of_shop === "営業不可") {
        this.dataFilter.state_of_shop = 2;
      } else if (this.state_of_shop === "不明") {
        this.dataFilter.state_of_shop = 3;
      } else {
        this.dataFilter.state_of_shop = 0;
      }
      if (this.file_image === "有") {
        this.dataFilter.file_image = 1;
      } else if (this.file_image === "無") {
        this.dataFilter.file_image = 2;
      } else {
        this.dataFilter.file_image = 0;
      }
      if (this.comment === "有") {
        this.dataFilter.comment = 1;
      } else if (this.comment === "無") {
        this.dataFilter.comment = 2;
      } else {
        this.dataFilter.comment = 0;
      }
      this.$emit("dataFilter", this.dataFilter);
      this.clearFilter = false;
      this.$emit("openDivFillter", false);
    },

    //get text show search
    getSearch() {
      if (
        this.therapist_nos == "" &&
        this.shop_prefectures == "" &&
        this.shop_nos == "" &&
        this.is_answers == "" &&
        this.is_workings == "" &&
        this.state_of_healths == "" &&
        this.is_can_works == "" &&
        this.state_of_shops == "" &&
        this.event_detail_names == "" &&
        this.is_auto_sends == "" &&
        this.file_images == "" &&
        this.comments == "" &&
        this.tp_prefectures == "" &&
        this.shop_areas == ""
      ) {
        return (this.valueDefault = "検索");
      }

      this.valueDefault =
        this.therapist_nos +
        this.shop_prefectures +
        this.shop_nos +
        this.is_answers +
        this.is_workings +
        this.state_of_healths +
        this.is_can_works +
        this.state_of_shops +
        this.event_detail_names +
        this.is_auto_sends +
        this.file_images +
        this.comments +
        this.tp_prefectures +
        this.shop_areas;
    },

    handleShopArea(e) {
      if (e.target.options.selectedIndex > -1) {
        let listAreas = this.list_area[e.target.options.selectedIndex];
        if (typeof listAreas.name == "undefined" || listAreas.name == "") {
          this.shop_areas = "";
        } else {
          this.shop_areas = " 店舗エリア: " + listAreas.name + ",";
        }
        this.getSearch();
        this.shop_area = listAreas.name;
        if (typeof listAreas.name == "undefined" || listAreas.name == "") {
          this.dataFilter.shop_area = "";
        } else {
          this.dataFilter.shop_area = listAreas.name;
        }
      }
    },

    handleTPPrefecture(e) {
      if (e.target.options.selectedIndex > -1) {
        let listTPPrefecture = this.list_tp_prefecture[
          e.target.options.selectedIndex
        ];
        if (
          typeof listTPPrefecture.name == "undefined" ||
          listTPPrefecture.name == ""
        ) {
          this.tp_prefectures = "";
        } else {
          this.tp_prefectures =
            " TP住所都道府県: " + listTPPrefecture.name + ",";
        }
        this.getSearch();
        this.tp_prefecture = listTPPrefecture.name;
        if (
          typeof listTPPrefecture.name == "undefined" ||
          listTPPrefecture.name == ""
        ) {
          this.dataFilter.tp_prefecture = "";
        } else {
          this.dataFilter.tp_prefecture = listTPPrefecture.name;
        }
      }
    },
    handlePrefecture(e) {
      if (e.target.options.selectedIndex > -1) {
        let listPrefecture = this.list_tp_prefecture[
          e.target.options.selectedIndex
        ];
        if (
          typeof listPrefecture.name == "undefined" ||
          listPrefecture.name == ""
        ) {
          this.shop_prefectures = "";
        } else {
          this.shop_prefectures = " 店舗都道府県: " + listPrefecture.name + ",";
        }
        this.getSearch();
        this.shop_prefecture = listPrefecture.name;
        if (
          typeof listPrefecture.name == "undefined" ||
          listPrefecture.name == ""
        ) {
          this.dataFilter.shop_prefecture = "";
        } else {
          this.dataFilter.shop_prefecture = listPrefecture.name;
        }
      }
    },
    setShopNo() {
      let inputShopNo = this.shop_no.trim();
      let countLength = inputShopNo.length;
      while (countLength < 4) {
        inputShopNo = "0" + inputShopNo;
        countLength = inputShopNo.length;
      }
      if (inputShopNo == "0000") {
        inputShopNo = "";
      }
      if (typeof inputShopNo == "undefined" || inputShopNo == "") {
        this.shop_nos = "";
      } else {
        this.shop_nos =
          " 店舗No: " + this.convertFullSizeToHalfSize(inputShopNo) + ",";
      }
      this.getSearch();
      if (typeof inputShopNo == "undefined" || inputShopNo == "") {
        this.dataFilter.shop_no = "";
      } else {
        this.dataFilter.shop_no = this.convertFullSizeToHalfSize(inputShopNo);
      }
    },
    handleIsAnswer(e) {
      if (e.target.options.selectedIndex > -1) {
        let listAnswer = this.list_is_answer[e.target.options.selectedIndex];
        if (typeof listAnswer.name == "undefined" || listAnswer.name == "") {
          this.is_answers = "";
        } else {
          this.is_answers = "  回答有無: " + listAnswer.name + ",";
        }
        this.getSearch();
        this.is_answer = listAnswer.name;
        if (typeof listAnswer.name == "undefined" || listAnswer.name == "") {
          this.dataFilter.is_answer = "";
        } else {
          this.dataFilter.is_answer = listAnswer.name;
        }
      }
    },
    handleIsWorking(e) {
      if (e.target.options.selectedIndex > -1) {
        let listWorking = this.list_is_working[e.target.options.selectedIndex];
        if (typeof listWorking.name == "undefined" || listWorking.name == "") {
          this.is_workings = "";
        } else {
          this.is_workings = " 当日業務: " + listWorking.name + ",";
        }
        this.getSearch();
        this.is_working = listWorking.name;
        if (typeof listWorking.name == "undefined" || listWorking.name == "") {
          this.dataFilter.is_working = "";
        } else {
          this.dataFilter.is_working = listWorking.name;
        }
      }
    },
    handlestateHealth(e) {
      if (e.target.options.selectedIndex > -1) {
        let listStateHealth = this.list_state_of_health[
          e.target.options.selectedIndex
        ];
        if (
          typeof listStateHealth.name == "undefined" ||
          listStateHealth.name == ""
        ) {
          this.state_of_healths = "";
        } else {
          this.state_of_healths = " けがの状況: " + listStateHealth.name + ",";
        }
        this.getSearch();
        this.state_of_health = listStateHealth.name;
        if (
          typeof listStateHealth.name == "undefined" ||
          listStateHealth.name == ""
        ) {
          this.dataFilter.state_of_health = "";
        } else {
          this.dataFilter.state_of_health = listStateHealth.name;
        }
      }
    },
    handlecanWork(e) {
      if (e.target.options.selectedIndex > -1) {
        let listCanWork = this.list_is_can_work[e.target.options.selectedIndex];
        if (typeof listCanWork.name == "undefined" || listCanWork.name == "") {
          this.is_can_works = "";
        } else {
          this.is_can_works = " 業務可否: " + listCanWork.name + ",";
        }
        this.getSearch();
        this.is_can_work = listCanWork.name;
        if (typeof listCanWork.name == "undefined" || listCanWork.name == "") {
          this.dataFilter.is_can_work = "";
        } else {
          this.dataFilter.is_can_work = listCanWork.name;
        }
      }
    },
    handleAutoSend(e) {
      if (e.target.options.selectedIndex > -1) {
        let listAutoSend = this.list_auto_send[e.target.options.selectedIndex];
        if (
          typeof listAutoSend.name == "undefined" ||
          listAutoSend.name == ""
        ) {
          this.is_auto_sends = "";
        } else {
          this.is_auto_sends = " 発報区分: " + listAutoSend.name + ",";
        }
        this.getSearch();
        this.is_auto_send = listAutoSend.name;
        if (
          typeof listAutoSend.name == "undefined" ||
          listAutoSend.name == ""
        ) {
          this.dataFilter.is_auto_send = "";
        } else {
          this.dataFilter.is_auto_send = listAutoSend.name;
        }
      }
    },
    handleStateShop(e) {
      if (e.target.options.selectedIndex > -1) {
        let listStateShop = this.list_state_of_shop[
          e.target.options.selectedIndex
        ];
        if (
          typeof listStateShop.name == "undefined" ||
          listStateShop.name == ""
        ) {
          this.state_of_shops = "";
        } else {
          this.state_of_shops = " 店舗状況: " + listStateShop.name + ",";
        }
        this.getSearch();
        this.state_of_shop = listStateShop.name;
        if (
          typeof listStateShop.name == "undefined" ||
          listStateShop.name == ""
        ) {
          this.dataFilter.state_of_shop = "";
        } else {
          this.dataFilter.state_of_shop = listStateShop.name;
        }
      }
    },
    handleFileImage(e) {
      if (e.target.options.selectedIndex > -1) {
        let listFileImage = this.list_file_image[
          e.target.options.selectedIndex
        ];
        if (
          typeof listFileImage.name == "undefined" ||
          listFileImage.name == ""
        ) {
          this.file_images = "";
        } else {
          this.file_images = " 添付ファイル: " + listFileImage.name + ",";
        }
        this.getSearch();
        this.file_image = listFileImage.name;
        if (
          typeof listFileImage.name == "undefined" ||
          listFileImage.name == ""
        ) {
          this.dataFilter.file_image = "";
        } else {
          this.dataFilter.file_image = listFileImage.name;
        }
      }
    },
    handleComment(e) {
      if (e.target.options.selectedIndex > -1) {
        let listComment = this.listNotices[e.target.options.selectedIndex];
        if (typeof listComment.name == "undefined" || listComment.name == "") {
          this.comments = "";
        } else {
          this.comments = " 特記事項: " + listComment.name + ",";
        }
        this.getSearch();
        this.comment = listComment.name;
        if (typeof listComment.name == "undefined" || listComment.name == "") {
          this.dataFilter.comment = "";
        } else {
          this.dataFilter.comment = listComment.name;
        }
      }
    },
    handleEventDetail(e) {
      if (e.target.options.selectedIndex > -1) {
        let listEventDetail = this.list_event_detail_name[
          e.target.options.selectedIndex
        ];
        if (
          typeof listEventDetail.name == "undefined" ||
          listEventDetail.name == ""
        ) {
          this.event_detail_names = "";
        } else {
          this.event_detail_names = " 送信一覧: " + listEventDetail.name + ",";
        }
        this.getSearch();
        this.event_detail_name = listEventDetail.name;
        if (
          typeof listEventDetail.name == "undefined" ||
          listEventDetail.name == ""
        ) {
          this.dataFilter.event_name = "";
          this.dataFilter.event_type = "";
        } else {
          this.dataFilter.event_name = listEventDetail.name;
          this.dataFilter.event_type = listEventDetail.event_type;
        }
      }
    },

    handleStartDate: function (data) {
      this.startDate = data;
      this.getDataEventName(this.startDate, this.endDate);
    },
    handleEndDate: function (data) {
      this.endDate = data;
      this.getDataEventName(this.startDate, this.endDate);
    },

    getDataEventName(startDate, endDate) {
      let urlEventName =
        Const.ROOT_API + Const.SUBMIT_PATH + Const.GET_DATA_EVENT_NAME;
      let param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      };
      axios
        .post(urlEventName, param)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.list_event_detail_name = res.data.data;
            this.list_event_detail_name.unshift("");
          } else {
            this.isLoading = true;
          }
        })
        .catch((err) => {
          return err;
        });
    },

    handleEvenEndDate: function (data) {
      this.endDateWork = data;
    },
    //set TherapistNo
    setTherapistNo: function () {
      let inputTherapistNo = this.therapist_no.trim();
      let countLength = inputTherapistNo.length;
      while (countLength < 7) {
        inputTherapistNo = "0" + inputTherapistNo;
        countLength = inputTherapistNo.length;
      }
      if (inputTherapistNo == "0000000") {
        inputTherapistNo = "";
      }
      if (typeof inputTherapistNo == "undefined" || inputTherapistNo == "") {
        this.therapist_nos = "";
      } else {
        this.therapist_nos =
          " セラピストNo: " +
          this.convertFullSizeToHalfSize(inputTherapistNo) +
          ",";
      }
      this.getSearch();
      if (typeof inputTherapistNo == "undefined" || inputTherapistNo == "") {
        this.dataFilter.therapist_no = "";
      } else {
        this.dataFilter.therapist_no = this.convertFullSizeToHalfSize(
          inputTherapistNo
        );
      }
    },
    // convert full-size to haft-size when change value input
    onChangeTherapistNo() {
      this.therapist_no = this.convertFullSizeToHalfSize(this.therapist_no);
    },
    onChangeShopNo() {
      this.shop_no = this.convertFullSizeToHalfSize(this.shop_no);
    },

    //clear data filter
    clearData() {
      this.valueDefault = "検索";

      this.therapist_no = "";
      this.therapist_nos = "";
      this.shop_prefectures = "";
      this.shop_prefecture = "";
      this.is_auto_send = "";
      this.is_auto_sends = "";
      this.comments = "";
      this.comment = "";
      this.tp_prefecture = "";
      this.tp_prefectures = "";
      this.shop_area = "";
      this.shop_areas = "";

      // clear vlue drop select
      this.shop_no = "";
      this.shop_nos = "";
      this.is_answer = 0;
      this.is_answers = "";
      this.is_working = 0;
      this.is_workings = "";
      this.state_of_health = 0;
      this.state_of_healths = "";
      this.is_can_work = 0;
      this.is_can_works = "";
      this.state_of_shop = 0;
      this.state_of_shops = "";
      this.file_image = 0;
      this.file_images = "";
      this.event_detail_name = "";
      this.event_detail_names = "";
      this.endDateWork = null;
      this.eventDate = null;
      this.endDate = new Date();
      this.startDate = new Date();
      this.dataFilter = {
        eventDate: "",
        therapist_no: "",
        shop_no: "",
        shop_prefecture: "",
        is_working: 0,
        last_work_date: "",
        event_name: "",
        event_type: "",
        is_auto_send: 0,
        is_answer: 0,
        state_of_health: 0,
        is_can_work: 0,
        state_of_shop: 0,
        file_image: 0,
        comment: 0,
        limit: 20,
        page: 1,
        start_date: moment(this.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.endDate).format("YYYY-MM-DD"),
      };

      this.getDataEventName(this.startDate, this.endDate);
    },
  },
};
</script>
<!-- Import style file -->
<style scoped src="@/assets/css/TP_AD1/TPAD1_Filter_Comp.css"></style>